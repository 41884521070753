@import "./pages/live.scss";
@import "./pages/messages.scss";
@import "./pages/orders.scss";
@import "./form/form.scss";
@import "./flex/flex.scss";
@import "./phone-input.scss";
@import "./rtl-body.scss";
@import "./pages/common/ListingComp.scss";
@font-face {
  font-family: "TheSans-Plain";
  src: url("https://db.onlinewebfonts.com/t/5c152170e85f42361141a732c78f11ad.eot");
  src: url("https://db.onlinewebfonts.com/t/5c152170e85f42361141a732c78f11ad.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/5c152170e85f42361141a732c78f11ad.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/5c152170e85f42361141a732c78f11ad.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/5c152170e85f42361141a732c78f11ad.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/5c152170e85f42361141a732c78f11ad.svg#TheSans-Plain")
      format("svg");
}
@font-face {
  font-family: "SF Pro Text";
  src: url("https://db.onlinewebfonts.com/t/ce6d64736389e5c4bd34a52bec4b733c.eot");
  src: url("https://db.onlinewebfonts.com/t/ce6d64736389e5c4bd34a52bec4b733c.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/ce6d64736389e5c4bd34a52bec4b733c.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/ce6d64736389e5c4bd34a52bec4b733c.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/ce6d64736389e5c4bd34a52bec4b733c.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/ce6d64736389e5c4bd34a52bec4b733c.svg#SF Pro Text")
      format("svg");
}

@font-face {
  font-family: "Noto Kufi Arabic";
  src: url("https://db.onlinewebfonts.com/t/3f157c3bb9fe4d694384fc9b1c1ea9b2.eot");
  src: url("https://db.onlinewebfonts.com/t/3f157c3bb9fe4d694384fc9b1c1ea9b2.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/3f157c3bb9fe4d694384fc9b1c1ea9b2.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/3f157c3bb9fe4d694384fc9b1c1ea9b2.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/3f157c3bb9fe4d694384fc9b1c1ea9b2.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/3f157c3bb9fe4d694384fc9b1c1ea9b2.svg#Noto Kufi Arabic")
      format("svg");
}

#root {
  // background-color: #fff;
  background-color: #f9fcff;
}

body {
  font-family: "Roboto" !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

$aside_background: rgba(14, 40, 87, 1);

$menu_active: rgba(233, 242, 255, 0.2);
$menu_hover_active: rgba(233, 242, 255, 0.08);

.aside-fixed {
  .aside {
    background-color: $aside_background;
    width: 300px;

    .aside-menu-wrapper {
      background-color: $aside_background;
    }

    .aside-menu {
      background-color: $aside_background;

      .power-by-container {
        // margin-top: 228px;
        text-align: center;
        font-family: "Roboto";
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        font-style: italic;
        line-height: 14px;
        letter-spacing: 0.8px;

        @media only screen and (max-width: 992px) {
          margin-bottom: 36px !important;
        }
      }
    }
  }

  .wrapper {
    padding-left: 300px;
  }
}

.ui.placeholder .line {
  background-color: #f9fcff !important;
}

.brand-dark {
  .brand {
    background-color: $aside_background;
  }
}

.aside-enabled {
  .header {
    &.header-fixed {
      left: 300px;
    }
  }

  &.subheader-fixed {
    .subheader {
      left: 300px;
    }
  }
}

.ui.dropdown .menu .selected.item[data-additional="true"] {
  color: #2185d0;
}

.aside-menu {
  .is-spacer {
    height: 20px;
  }

  .menu-nav {
    .menu-text {
      font-size: 18px !important;
      color: #e9f2ff !important;
    }

    .menu-item {
      margin-inline: 15px;

      &.menu-item-category-link-rtl {
        margin-left: 21px !important;
        margin-right: 20px !important;
      }

      &.menu-item-category-link {
        margin-left: 23px;
        margin-right: 15px;
      }

      &.menu-item-active {
        > .menu-link {
          background-color: rgba(233, 242, 255, 0.2) !important;
          border-radius: 3px;

          &:hover {
            background-color: rgba(233, 242, 255, 0.2) !important;
            border-radius: 3px;
          }

          &.accordion-link {
            background-color: transparent !important;
            border-radius: 0px !important;
          }
        }
      }

      &:hover {
        > .menu-link {
          background-color: rgba(233, 242, 255, 0.08) !important;
          border-radius: 3px;

          &.accordion-link {
            background-color: transparent !important;
            border-radius: 0px !important;
          }
        }
      }
    }

    .menu-item {
      > .menu-link .menu-icon.svg-icon svg g [fill] {
        fill: #e2f5fc;
      }

      &.menu-item-active,
      &:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover {
        > .menu-heading,
        > .menu-link {
          .menu-icon {
            // padding: 9px 12px;
            // background-color: rgba(233, 242, 255, 0.2);
            // margin-right: -14px;
            // border-radius: 3px;
            &.svg-icon {
              svg {
                g {
                  [fill] {
                    fill: #e2f5fc;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.aside-minimize {
  .aside-menu {
    .menu-nav {
      .menu-item {
        &.menu-item-active {
          > .rtl-menu-link {
            background-color: transparent;
            border-radius: 0px;
            &.accordion-link {
              background-color: transparent !important;
              border-radius: 0px !important;
            }
          }
        }

        &:hover {
          > .rtl-menu-link {
            background-color: transparent !important;
            border-radius: 0px;
            &.accordion-link {
              background-color: transparent !important;
              border-radius: 0px !important;
            }
          }
        }
      }

      .menu-item {
        &.menu-item-active,
        &:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover {
          > .menu-heading,
          > .rtl-menu-link {
            .rtl-menu-icon {
              padding: 9px 12px;
              background-color: rgba(233, 242, 255, 0.2);
              margin-right: -14px;
              border-radius: 3px;
            }
          }
        }
      }
    }
  }
}

.aside-minimize-hover {
  .aside-menu {
    .is-spacer {
      height: 20px;
    }

    .menu-nav {
      .menu-text {
        font-size: 18px !important;
        color: #e9f2ff !important;
      }

      .menu-item {
        margin-inline: 15px;

        &.menu-item-category-link {
          margin-left: 24px;
        }

        &.menu-item-active {
          > .rtl-menu-link {
            background-color: rgba(233, 242, 255, 0.2);
            border-radius: 3px;

            &.accordion-link {
              background-color: transparent !important;
              border-radius: 0px !important;
            }
          }
        }

        &:hover {
          > .rtl-menu-link {
            background-color: rgba(233, 242, 255, 0.2) !important;
            border-radius: 3px;

            &.accordion-link {
              background-color: transparent !important;
              border-radius: 0px !important;
            }
          }
        }
      }

      .menu-item {
        > .menu-link .menu-icon.svg-icon svg g [fill] {
          fill: #e2f5fc;
        }

        &.menu-item-active,
        &:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover {
          > .menu-heading,
          > .rtl-menu-link {
            .rtl-menu-icon {
              padding: 0;
              background-color: transparent;
              margin-right: 0px;
              border-radius: 0px;
              &.svg-icon {
                svg {
                  g {
                    [fill] {
                      fill: #e2f5fc;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
// .aside-minimize-hover {
//   .aside-menu {
//     .menu-nav {
//       .menu-item {
//         &.menu-item-active {
//           > .rtl-menu-link {
//             background-color: rgba(233, 242, 255, 0.2);
//             border-radius: 3px;
//           }
//         }

//         &:hover {
//           > .rtl-menu-link {
//             background-color: rgba(233, 242, 255, 0.2);
//             border-radius: 3px;
//           }
//         }
//       }

//       .menu-item {
//         &.menu-item-active,
//         &:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover {
//           > .menu-heading,
//           > .rtl-menu-link {
//             .rtl-menu-icon {
//               padding: 0;
//               background-color: transparent;
//               margin-right: 0px;
//               border-radius: 0px;
//             }
//           }
//         }
//       }
//     }
//   }
// }

.brand-dark {
  .brand {
    .btn {
      .svg-icon {
        svg {
          g {
            [fill] {
              fill: #e2f5fc;
            }
          }
        }
      }

      &.active,
      &:hover {
        .svg-icon {
          svg {
            g {
              [fill] {
                fill: #e2f5fc;
              }
            }
          }
        }
      }
    }
  }
}

.menu-item-category {
  padding: 9px 12px !important;
}

.header-mobile {
  img[alt="logo"] {
    max-width: 130px;
  }
}

.fix-right-dashboard {
  margin-left: -86px;

  @media only screen and (max-width: 992px) {
    margin-left: -20px;
  }

  // &.rtl-right-dashboard{

  //   margin-left: -11px;
  // }

  .container {
    margin-left: 0;
  }

  .header {
    left: 0 !important;
  }
}

.aside-fixed.aside-minimize:not(.aside-minimize-hover)
  .wrapper
  .fix-right-dashboard {
  margin-left: -24px;
  // padding-left: 15px;
  // margin-left: 0;
}

.aside-minimize-hover {
  .fix-right-dashboard {
    // margin-left: 145px;
  }
}

.aside-enabled.subheader-fixed .subheader,
.aside-fixed.aside-minimize.aside-minimize-hover .subheader {
  left: 304px;
}

.subheader-fixed.aside-minimize .subheader {
  left: 110px;
}

.aside-fixed.aside-minimize.aside-minimize-hover .wrapper .fix-right-dashboard {
  // padding-left: 205px;
  // margin-left: 145px;
}

.aside-fixed.aside-minimize:not(.aside-minimize-hover) .wrapper .footer {
  padding-left: 40px;
  margin-left: 0;
}

.direction-rtl .footer .container-fluid {
  padding-right: 0;
}

.aside-fixed.aside-minimize.aside-minimize-hover .wrapper .footer {
  // padding-left: 225px;
  margin-left: 0;
}

.aside-minimize .direction-rtl .subheader {
  right: 110px !important;
}
.aside-minimize {
  .accordion {
    .rtl-accordian {
      // padding: 9px 12px !important;
      margin-left: 11px;
      padding: 2px !important;
      margin-right: 9px !important;
    }
    .title {
      // padding: 9px 12px !important;
      margin-left: 11px;
      padding: 2px !important;
      margin-right: 0px;
    }
  }

  .menu-item-category {
    padding: 1px 6px !important;
  }

  .aside-menu {
    display: block !important;
    .menu-nav .menu-item.menu-item-category-link {
      .menu-item-category-link-rtl {
        margin-right: 13px;
      }
    }
  }

  .menu-text {
    margin-right: 12px !important  ;
  }

  .power-by-container {
    display: none !important;
  }
}

.aside-minimize-hover {
  .header {
    left: 0px !important;
  }
  .accordion {
    .title {
      // padding: 9px 12px !important;
      margin-left: 13px;
      padding: 2px !important;
      margin-right: 13px;
    }
  }

  .menu-item-category {
    padding: 9px 12px !important;
  }

  .aside-menu {
    display: flex !important;
  }

  .menu-text {
    margin-left: 2px !important  ;
  }

  .power-by-container {
    display: block !important;
  }
}

.direction-rtl .custom-subheader,
.aside-minimize-hover .direction-rtl .custom-subheader {
  right: 306px !important;
}

.aside-minimize .direction-rtl .aside-fixed + .wrapper {
  padding-right: 105px;
}

.aside-minimize-hover .direction-rtl .aside-fixed + .wrapper {
  padding-right: 300px;
}

.direction-rtl .header#kt_header.header-fixed {
  right: 0 !important;
}

.aside-enabled .header.header-fixed {
  left: 0;
}

.aside-fixed.aside-minimize:not(.aside-minimize-hover)
  .direction-rtl
  .wrapper
  .fix-right-dashboard {
  // padding-left: 0;
  margin-left: -23px;
  margin-right: -33px;
}

.direction-rtl {
  .ui.form .field > label {
    font-family: "Noto Kufi Arabic" !important;
  }

  .workorder-intro-label {
    font-family: "Noto Kufi Arabic" !important;
  }
  .workorder-intro-value {
    font-family: "Noto Kufi Arabic" !important;
  }
  .label-item {
    font-family: "Noto Kufi Arabic" !important;
  }
  .container {
    margin-right: 0;
    margin-left: auto;
  }

  .fix-right-dashboard {
    margin-left: -24px;
    margin-right: -62px;

    @media only screen and (max-width: 992px) {
      margin-right: -27px;
    }
  }
}

.aside-fixed .aside {
  width: 237px;
}

.modal-text-area {
  width: 100%;
  padding: 5px;
  font-size: 16px;
}

.comment-container {
  .comment-label {
    // font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;

    &.po {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .add-comment {
    .comment-textarea {
      min-width: 468px;
      border-radius: 3px;
      border: 2px solid var(--light-color-border-input, rgba(9, 30, 66, 0.14));
      background: rgba(233, 242, 255, 0.1);
      padding: 5px;
    }

    .comment-textarea::placeholder {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      // line-height: 20px; /* 142.857% */
      // letter-spacing: 0.25px;
    }
  }
}

.comment {
  font-size: 16px;
  background: rgba(9, 30, 66, 0.03);
  margin-top: 15px;
  padding: 10px;

  &.work-request {
    background: #e9f2ff66;
    border-radius: 4px;
    padding: 10px 10px 1px 10px;
  }
  .desc-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    color: #44546f;
  }

  &.decline-container {
    background: #d140401a;
    padding: 13px 14px 5px 18px;
    border-radius: 8px !important;
    border: 1px solid;

    &.decline-border {
      border-color: #db2828 !important;
    }
  }

  &.approved-container {
    background: #4fab681a;
    padding: 13px 14px 5px 18px;
    border-radius: 8px !important;
    border: 1px solid;

    &.approve-border {
      border-color: #4fab68 !important;
    }
  }

  &.approve-container {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  .user-img {
    margin-top: 2px;
  }

  label {
    span {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: 0.5px;
      color: #172b4d;
      margin-left: 5px;
    }
    .time {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.25px;
      color: #788ca5;
      margin-left: 10px;
    }
  }
  .comment-text {
    color: #44546f;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    margin-left: 36px;
  }
}

.procedure-progress {
  border-radius: 8px;
  border: 1px solid #e4e6ea;
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .progress-number {
    font-weight: 500;
  }
}

.date-input-picker {
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: "Roboto", "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
  width: 100% !important;
  padding: 9px 30px !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.PhoneInput {
  input {
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: 1.21428571em;
    font-family: "Roboto", "Helvetica Neue", Arial, Helvetica, sans-serif;
    padding: 0.67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.28571429rem;
    transition: box-shadow 0.1s ease, border-color 0.1s ease;
    box-shadow: none;
  }
}

.accordion {
  .title {
    // padding: 9px 12px !important;
    margin-left: 13px;
    padding: 2px !important;
    margin-right: 13px;

    &:hover {
      background-color: $menu_hover_active !important;
      padding: 2px !important;
      margin-right: 13px;
      border-radius: 3px;
    }
  }

  .content {
    .menu-item {
      // padding: 9px 12px !important;

      &.menu-item-active {
        background-color: $menu_active !important;
        border-radius: 3px !important;
        &:hover {
          background-color: $menu_active !important;
          border-radius: 3px !important;
        }
      }

      &:hover {
        background-color: $menu_hover_active !important;
        border-radius: 3px !important;
      }
    }
  }
}

.aside-minimize {
  .rtl-accordian-title {
    .content {
      .rtl-menu-item {
        &.menu-item {
          // padding: 9px 12px !important;

          &.menu-item-active {
            background-color: transparent !important;
            border-radius: 0px !important;
          }

          &:hover {
            background-color: transparent !important;
            border-radius: 0px !important;
          }
        }
      }
    }
  }
}

.aside-minimize-hover {
  .rtl-accordian-title {
    .content {
      .rtl-menu-item {
        &.menu-item {
          // padding: 9px 12px !important;

          &.menu-item-active {
            background-color: $menu_active !important;
            border-radius: 3px !important;
          }

          &:hover {
            background-color: $menu_hover_active !important;
            border-radius: 3px !important;
          }
        }
      }
    }
  }
}

fieldset {
  border: 1px solid #000;
  padding: 10px !important;
}

legend {
  float: none;
  width: auto;
  padding: 10px !important;
  font-weight: bold;
}

.invited-by-tag {
  font-weight: 900;
  font-size: 16px;
}

.status-modal-popup-message {
  color: var(--main-text, #172b4d);
  text-align: center;
  /* .Internal/Button/Button Label */
  // font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

// image crop modal
.img-crop-wrapper-scroll {
  .simplebar-placeholder {
    display: none;
  }
}

.content-heignt-min {
  min-height: 80vh;
}

.user-image-placeholder-container {
  padding: 5px;
  border-radius: 50%;
  background-color: #3f51b5;
  font-weight: 900;
}

// .dropdown {
//   .avatar {
//     border-radius: 50% !important;
//     width: 20px !important;
//     height: 20px !important;
//   }
// }

.highlight-selected-date {
  background-color: #3699ff2b;
}

.fc-daygrid-day {
  height: 9.5rem;
  /* Adjust the height as needed */
}

// .pagination {
//   margin-bottom: 4rem !important;
// }
@media print {
  .qr-code-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    div {
      text-align: center;
      transform: scale(2);
      svg {
        transform: scale(2);

        margin-bottom: 40px;
      }
      p {
        margin-top: 20px;
        display: block !important;
        font-size: 16px;
      }
    }
  }

  /* Add any additional print-specific styles here */
}

@import "preview";

.custom-aside-menu {
  .menu-nav {
    .menu-item {
      .menu-link {
        min-height: 40px;
        align-items: center;
        .menu-text {
          font-size: 14px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .aside-fixed .wrapper {
    padding-left: 20px;
  }

  .flexbox {
    flex-wrap: wrap;
    row-gap: 10px;
  }

  .direction-rtl {
    .aside-left {
      right: -265px !important;
    }

    .aside-on {
      right: 0 !important;
    }
  }

  .direction-rtl .aside-fixed + .wrapper {
    padding-right: 20px !important;
  }
}

.people-modal-text {
  color: #44546f;
  // font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  p {
    color: #788ca5;
    // font-family: 'Roboto';
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
}

.terms-conditions {
  padding: 60px 0;
  font-size: 16px;

  h1 {
    margin-bottom: 40px;
  }

  .terms {
    > p {
      margin-bottom: 20px;
    }
  }

  .container {
    max-width: 1000px;
  }

  .main-list {
    li {
      margin-bottom: 15px;
      padding-left: 15px;
    }

    > p {
      padding-left: 15px;
    }

    .list {
      padding-left: 45px;
      li {
        position: relative;

        &::before {
          content: "2.";
          position: absolute;
          top: 0;
          left: -31px;
        }
      }
    }

    .list-3 {
      li {
        &::before {
          content: "3.";
        }
      }
    }

    .list-4 {
      li {
        &::before {
          content: "4.";
        }
      }
    }

    .list-5 {
      li {
        &::before {
          content: "5.";
        }
      }
    }

    .list-6 {
      li {
        &::before {
          content: "6.";
        }
      }
    }

    .list-7 {
      li {
        &::before {
          content: "7.";
        }
      }
    }

    .list-8 {
      li {
        &::before {
          content: "8.";
        }
      }
    }

    .list-9 {
      li {
        &::before {
          content: "9.";
        }
      }
    }

    .list-10 {
      li {
        &::before {
          content: "10.";
        }
      }
    }

    .list-11 {
      li {
        &::before {
          content: "11.";
        }
      }
    }

    .two-digit {
      padding-left: 55px;

      li {
        &::before {
          left: -40px;
        }
      }
    }
  }

  &.privacy {
    h3 {
      margin-bottom: 5px;
    }

    ol[start="2"],
    ol[start="3"] {
      margin-top: 30px;
      > li {
        margin-bottom: 30px;
      }
    }

    .main-list {
      .list {
        list-style: none;
        padding-left: 0;

        &.list-2 {
          li {
            p {
              margin: 30px 0;
            }
          }
        }

        li {
          u {
            display: block;
          }

          &::before {
            content: "";
          }

          p {
            margin-bottom: 5px;
          }

          .sub-list {
            list-style: disc;
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}

.header-fixed.subheader-fixed.subheader-enabled .wrapper {
  background-color: #f9fcff;
}

.header-fixed.subheader-fixed.subheader-enabled .direction-rtl .wrapper {
  padding-left: 0;
}
.header-fixed.subheader-fixed.subheader-enabled
  .direction-rtl
  .wrapper
  .footer {
  padding-left: 10px;
}

.header-fixed.subheader-fixed.subheader-enabled
  .direction-rtl
  .wrapper
  .footer {
  .nav .nav-link {
    padding: 0 10px !important;
  }
}

.deactive_work_order_list {
  overflow-x: auto;
  max-height: 229px;
  li {
    p {
      font-size: 16px;
    }
  }
}

.area-input-icon {
  display: flex;
  align-items: center;
  margin: 10px;
}
.area-input-div {
  width: 385px;
}
.notification-container {
  overflow-y: "scroll";
  .notification-div {
    position: "relative";
    // .infinite-scroll-component {
    max-height: 400px;
    // }
  }
}

.avatar {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.popup-filter {
  height: 100%;
  line-height: 1.21428571em !important;
  font-size: 1rem !important;
  font-weight: 300 !important;
  cursor: pointer !important;
  color: #00000099 !important;
}
.popup-filter:hover {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.8) !important;
  border: 1px solid rgba(34, 36, 38, 0.35) !important;
}

.calendar-datepicker > .react-datepicker-wrapper {
  width: auto !important;
}
.calendar-datepicker {
  .react-datepicker-popper {
    z-index: 100 !important;
  }
}
.notification-count {
  background: #0c66e4;
  // width: 28px;
  min-width: 28px;
  height: 16px;
  border-radius: 8px;
  padding: 2px 6px;
  font-size: 12px;
  line-height: 13px;
  color: #fff;
  font-weight: 400;
  position: absolute;
  top: 3px;
  right: 0px;
}
.bell-container {
  position: relative;
}
.ui.secondary.vertical.menu > .item {
  font-family: "Noto Kufi Arabic", sans-serif !important;
}
// .cancel-text {
//   text-align: center !important;
// }
.user-type-popup-rtl {
  font-family: "Noto Kufi Arabic", Poppins, Helvetica, sans-serif !important;
  button {
    font-family: "Noto Kufi Arabic", Poppins, Helvetica, sans-serif !important;
  }
  .ui.header {
    font-family: "Noto Kufi Arabic", Poppins, Helvetica, sans-serif !important;
  }
  .ui.segment {
    justify-content: right !important;
    text-align: right !important;
  }
  .actions {
    text-align: left !important;
  }
}

.asset-filter-rtl {
  .label-item {
    text-align: right !important;

    &.category-input {
      .ui.input > input:focus {
        border-color: #0c66e4;
      }
    }
  }

  .filter-button-container {
    text-align: right !important;
  }
}

.rdt_TableHead {
  height: 40px !important;
  align-items: center;
  .rdt_TableHeadRow {
    color: #172b4d;
    /* Title Small 14\20 */
    font-family: Roboto;
    padding-bottom: 10px !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
    background-color: #f5f6f8 !important;
    height: 40px !important;
    min-height: 40px !important;
  }
}
.rdt_TableRow {
  color: #172b4d !important;
  cursor: pointer !important;
  /* Body Large 16\24 */
  font-family: Roboto;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px !important; /* 150% */
  letter-spacing: 0.25px;
}
.id-text-style {
  font-weight: 500 !important;
  letter-spacing: 0.15px !important;

  &.work-request {
    color: #0c66e4;
    text-align: center;
    //font-family: Roboto;
    line-height: 24px; /* 150% */
    text-decoration-line: underline;
    cursor: pointer;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
// input[type="time"]::-webkit-calendar-picker-indicator {
//   display: none;
//   -webkit-appearance: none;
// }

.time-text-style {
  color: #788ca5;
  text-align: center;
  /* Body Medium 14\20 */

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.common-dashboard {
  .container {
    max-width: 100%;
  }
}
.aside-modal {
  max-width: 514px;

  &.procedure {
    overflow: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px !important;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #788ca5;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  &.po {
    max-width: 550px;
  }
}
.category-bulk-result-modal {
  .rdt_TableHead {
    .rdt_TableHeadRow {
      font-family: "Noto Kufi Arabic", sans-serif !important;
      text-align: start !important;
    }
  }
  .header {
    font-family: "Noto Kufi Arabic", sans-serif !important;
  }
  .rdt_TableRow {
    font-family: "Noto Kufi Arabic", sans-serif !important;
  }
}

.Category-dropdown {
  // min-width: 104px;
  .title-text {
    color: rgba(120, 140, 165, 1);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    font-weight: 400;
  }

  &.blue-border {
    border-color: #0c66e4;
    color: #44546f;
    background-color: transparent;
  }

  &.popup {
    color: #44546f !important;
    height: 100%;
    font-weight: 400 !important;
    border-radius: 3px;
    font-size: 14px !important;
    padding: 5px 6px;
    border: 2px solid rgba(9, 30, 66, 0.14);
    background-color: transparent;

    &.blue-border {
      border-color: #0c66e4;
      color: #44546f;
      background-color: transparent;
      &:hover {
        border: 2px solid rgba(9, 30, 66, 0.14) !important;
        border-color: #0c66e4 !important;
        border-radius: 3px;
        color: #44546f !important;
        background-color: transparent !important;
      }
    }

    &.active {
      border-color: #0c66e4;
      color: #44546f;
      background-color: transparent;
    }

    &:hover {
      border: 2px solid rgba(9, 30, 66, 0.14) !important;
      border-color: #0c66e4 !important;
      border-radius: 3px;
      color: #44546f !important;
      background-color: transparent !important;
    }
  }

  .no-option-div {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    color: #44546f;
    margin-top: 0;
  }

  &.priority {
    &.purchase-order {
      .dropdown {
        .menu {
          max-width: 180px !important;
        }
      }
    }
    &.orderType {
      .dropdown {
        .menu {
          max-width: 210px !important;
        }
      }
    }
    .dropdown {
      .menu {
        max-width: 155px;
      }
    }
  }

  .dropdown {
    height: auto;

    &.blue-border {
      button {
        height: 100%;

        font-weight: 400;
        border-radius: 3px;
        font-size: 14px;
        padding: 5px 6px;
        border: 2px solid #0c66e4 !important;
        background-color: transparent;
        color: #44546f;

        &:hover {
          border-color: #0c66e4 !important;
          color: #44546f !important;
          background-color: transparent !important;
        }
      }
    }

    button {
      height: 100%;

      font-weight: 400;
      border-radius: 3px;
      font-size: 14px;
      padding: 5px 6px;
      border: 2px solid rgba(9, 30, 66, 0.14);
      background-color: white;
      color: #44546f;

      &:hover {
        border-color: #0c66e4 !important;
        color: #44546f !important;
        background-color: white !important;
      }
    }

    .right {
      .menu {
        right: 0px;
      }
    }

    .menu {
      max-height: 390px;
      overflow-y: scroll;
      display: none;
      position: absolute;
      z-index: 999;
      background-color: #fff;
      width: 246px;
      border: 1px solid #e4e6ea;
      top: 100%;
      margin-top: 5px;

      &.right-dropdown {
        right: 0px;
      }
      &.left-dropdown {
        left: 0px;
      }

      .custom-checkbox:has(.checkbox-item input:checked) {
        background-color: rgba(245, 246, 248, 1);
      }

      .item {
        padding: 8px 17px 7px 44px;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e4e6ea;
        // font-family: 'Roboto', sans-serif;
        &.single-select {
          padding: 11px 10px 11px 16px !important;
        }

        &:hover {
          // background-color: rgba(245, 246, 248, 1);
        }
      }
    }

    &.dropdown {
      &.active {
        button {
          svg {
            transform: rotate(-180deg);
          }

          border-color: #0c66e4;
          color: #44546f;
          background-color: transparent;
        }

        .menu {
          display: block;

          .item {
            // border-bottom: none;

            h3 {
              font-size: 14px;
              font-weight: 400;
              color: #44546f;
              margin-top: 0;
            }

            &.checkeditem {
              // background-color: red;
            }

            /* The container */
            &.custom-checkbox {
              position: relative;
              cursor: pointer;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              margin-bottom: 0;
              &.single-select {
                border-left: 2px solid #fff;
              }
              &.selected {
                border-left: 2px solid #0c66e4;
                background-color: rgba(228, 230, 234, 0.5) !important;
              }

              &:hover {
                border-left: 2px solid #0c66e4;
                // background-color: rgba(228, 230, 234, 0.5) !important;
              }
            }

            /* Hide the browser's default checkbox */
            &.custom-checkbox .checkbox-item input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
            }

            /* Create a custom checkbox */
            .checkmark {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 22px;
              height: 12px;
              width: 12px;
              background-color: transparent;
              border: 2px solid rgba(9, 30, 66, 0.14);
              border-radius: 3px;
            }

            /* On mouse-over, add a grey background color */
            &.custom-checkbox:hover input ~ .checkmark {
              // background-color: #ccc;
            }

            /* When the checkbox is checked, add a blue background */
            &.custom-checkbox .checkbox-item input:checked ~ .checkmark {
              background-image: url("../Assets/Icons/dropdown-checkbox.svg");
              background-repeat: no-repeat;
              width: 12px;
              height: 12px;
              border: none;
            }

            /* Create the checkmark/indicator (hidden when not checked) */
            .checkmark:after {
              content: "";
              position: absolute;
              display: none;
            }

            /* Show the checkmark when checked */
            &.custom-checkbox .checkbox-item input:checked ~ .checkmark:after {
              display: block;
            }

            /* Style the checkmark/indicator */
            &.custom-checkbox .checkbox-item .checkmark:after {
              background: #0c66e4;
              left: -22px;
              height: 37px;
              width: 2px;
              top: -14px;
              // border: solid white;
              // border-width: 0 3px 3px 0;
              // -webkit-transform: rotate(45deg);
              // -ms-transform: rotate(45deg);
              // transform: rotate(45deg);
            }

            svg {
              margin-right: 5px;
            }

            &.divider {
              border-bottom: 1px solid #e4e6ea;
              padding: 0;
            }

            .data-svg{
              svg{
                margin-left: 0px;
                margin-right: 0px;
              }
            }

            svg {
              margin-left: 5px;
              margin-right: 9px;
            }
          }
        }
      }
    }
  }
}

.Part-dropdown {
  // min-width: 104px;
  .title-text {
    color: rgba(120, 140, 165, 1);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    font-weight: 400;
  }

  &.blue-border {
    border-color: #0c66e4;
    color: #44546f;
    background-color: transparent;
  }

  &.popup {
    color: #44546f !important;
    height: 100%;
    font-weight: 400 !important;
    border-radius: 3px;
    font-size: 14px !important;
    padding: 5px 6px;
    border: 2px solid rgba(9, 30, 66, 0.14);
    background-color: transparent;

    &.blue-border {
      border-color: #0c66e4;
      color: #44546f;
      background-color: transparent;
      &:hover {
        border: 2px solid rgba(9, 30, 66, 0.14) !important;
        border-color: #0c66e4 !important;
        border-radius: 3px;
        color: #44546f !important;
        background-color: transparent !important;
      }
    }
  }

  .no-option-div {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    color: #44546f;
    margin-top: 0;
  }

  &.priority {
    .dropdown {
      .menu {
        max-width: 155px;
      }
    }
  }

  .dropdown {
    height: 100%;

    &.blue-border {
      button {
        height: 100%;
        width: 100%;

        font-weight: 400;
        border-radius: 3px;
        font-size: 14px;
        padding: 5px 6px;
        border: 2px solid #0c66e4 !important;
        background-color: transparent;
        color: #44546f;
      }
    }

    button {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      text-align: start;
      font-weight: 400;
      border-radius: 3px;
      font-size: 14px;
      padding: 5px 6px;
      border: 2px solid rgba(9, 30, 66, 0.14);
      background-color: transparent;
      color: #44546f;
    }

    .right {
      .menu {
        right: 0px;
      }
    }

    .menu {
      max-height: 200px;
      width: 100%;
      overflow-y: scroll;
      display: none;
      position: absolute;
      z-index: 1;
      background-color: #fff;
      border: 2px solid blue;
      top: 100%;
      margin-top: 5px;

      &.right-dropdown {
        right: 0px;
      }
      &.left-dropdown {
        left: 0px;
      }

      .custom-checkbox:has(.checkbox-item input:checked) {
        background-color: rgba(245, 246, 248, 1);
      }

      .item {
        padding: 8px 17px 11px 44px;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e4e6ea;
        // font-family: 'Roboto', sans-serif;
        &.single-select {
          padding: 11px 10px 11px 16px !important;
        }

        &:hover {
          // background-color: rgba(245, 246, 248, 1);
        }
      }
    }

    &.dropdown {
      &.active {
        button {
          svg {
            transform: rotate(-180deg);
          }
        }

        .menu {
          display: block;

          .item {
            // border-bottom: none;

            h3 {
              font-size: 14px;
              font-weight: 400;
              color: #44546f;
              margin-top: 0;
            }

            &.checkeditem {
              // background-color: red;
            }

            /* The container */
            &.custom-checkbox {
              position: relative;
              cursor: pointer;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              margin-bottom: 0;
              &.single-select {
                border-left: 2px solid #fff;
              }
              &.selected {
                border-left: 2px solid #0c66e4;
              }
            }

            /* Hide the browser's default checkbox */
            &.custom-checkbox .checkbox-item input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
            }

            /* Create a custom checkbox */
            .checkmark {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 22px;
              height: 12px;
              width: 12px;
              background-color: transparent;
              border: 2px solid rgba(9, 30, 66, 0.14);
              border-radius: 3px;
            }

            /* On mouse-over, add a grey background color */
            &.custom-checkbox:hover input ~ .checkmark {
              // background-color: #ccc;
            }

            /* When the checkbox is checked, add a blue background */
            &.custom-checkbox .checkbox-item input:checked ~ .checkmark {
              background-image: url("../Assets/Icons/dropdown-checkbox.svg");
              background-repeat: no-repeat;
              width: 12px;
              height: 12px;
              border: none;
            }

            /* Create the checkmark/indicator (hidden when not checked) */
            .checkmark:after {
              content: "";
              position: absolute;
              display: none;
            }

            /* Show the checkmark when checked */
            &.custom-checkbox .checkbox-item input:checked ~ .checkmark:after {
              display: block;
            }

            /* Style the checkmark/indicator */
            &.custom-checkbox .checkbox-item .checkmark:after {
              background: #0c66e4;
              left: -22px;
              height: 41px;
              width: 2px;
              top: -14px;
              // border: solid white;
              // border-width: 0 3px 3px 0;
              // -webkit-transform: rotate(45deg);
              // -ms-transform: rotate(45deg);
              // transform: rotate(45deg);
            }

            svg {
              margin-right: 5px;
            }

            &.divider {
              border-bottom: 1px solid #e4e6ea;
              padding: 0;
            }

            svg {
              margin-left: 5px;
              margin-right: 9px;
            }
          }
        }
      }
    }
  }
}

.wrapper-parts-dropdown {
  .Part-dropdown
    .dropdown.dropdown.active
    .menu
    .item.custom-checkbox
    .checkbox-item
    input:disabled
    ~ .checkmark {
    background-color: #e4e6ea !important;
  }
  .Part-dropdown
    .dropdown.dropdown.active
    .menu
    .item.custom-checkbox
    .checkbox-item
    input:disabled
    ~ label {
    background-color: #e4e6ea !important;
  }
  .Part-dropdown .dropdown.dropdown.active .menu .item h3 {
    margin: 0 15px 3px 0;
    word-break: break-word;
  }
  .Part-dropdown
    .dropdown.dropdown.active
    .menu
    .item.custom-checkbox
    .checkbox-item
    .checkmark:after {
    left: -22px;
    height: 38px;
    width: 3px;
    top: -18px;
  }

  .Part-dropdown .active button {
    border-color: blue !important;
  }
  .Part-dropdown .dropdown button {
    line-height: 24px;
    color: rgba(191, 191, 191, 0.87) !important;
    border-radius: 5px !important;
    background-color: transparent !important;
    // .btn.btn-secondary:focus:not(.btn-text),

    &:hover {
      background-color: transparent !important;
      color: rgba(191, 191, 191, 0.87) !important;
      border-color: blue !important;
    }
    div .option-part-drop {
      background-color: #e8e8e8;
      box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
      margin: 3px 5px !important;
      color: #44546f !important;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
      border-radius: 3px;
      padding: 5px 11px;
      word-break: break-all;
      display: inline-block;
    }
  }
  .Part-dropdown .no-option-div {
    height: auto;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    /* margin-top: -10px; */
    padding: 0.78571429rem 1.14285714rem;
    color: rgba(0, 0, 0, 0.4);
  }
  .Part-dropdown .dropdown .menu {
    margin-top: 0px;
    border-radius: 5px;
  }
  .widt-avai-part {
    width: -webkit-fill-available;
    color: #44546f !important;
    letter-spacing: 0.4px;

    .disable-txt-part {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.4);
    }
  }
}

.rmdp-wrapper {
  padding: 2px 16px 1px 16px;
  width: 321px !important;
}
.Date-dropdown {
  .title-text {
    color: rgba(120, 140, 165, 1);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    font-weight: 400;
  }

  &.popup {
    color: #44546f !important;
    font-family: "Roboto", sans-serif !important;
    height: 100%;
    font-weight: 400 !important;
    border-radius: 3px;
    font-size: 14px !important;
    padding: 5px 6px;
    border: 2px solid rgba(9, 30, 66, 0.14);
    background-color: transparent;

    &.active {
      font-family: "Roboto", sans-serif;
      border-color: #0c66e4;
      color: #44546f;
      background-color: transparent;
    }

    &:hover {
      border: 2px solid rgba(9, 30, 66, 0.14) !important;
      border-color: #0c66e4 !important;
      border-radius: 3px;
      color: #44546f !important;
      background-color: transparent !important;
    }
  }

  .no-option-div {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    color: #44546f;
    margin-top: 0;
  }

  .dropdown {
    // height: 100%;

    button {
      font-weight: 400;
      background-color: #fff;
      border-radius: 3px;
      font-size: 14px;
      padding: 5px 6px;
      border: 2px solid rgba(9, 30, 66, 0.14);
      color: #44546f;

      &:hover {
        border-color: #0c66e4 !important;
        color: #44546f !important ;
        background-color: transparent !important;
      }
    }

    .menu {
      // max-height: 390px;
      min-height: 115px;
      max-width: 195px;
      // overflow-y: scroll;
      display: none;
      position: absolute;
      z-index: 10;
      background-color: #fff;
      width: 246px;
      border: 1px solid #e4e6ea;
      top: 100%;
      margin-top: 5px;

      .btn-datepicker {
        .blue {
          border-color: transparent !important;
          color: #44546f !important ;
          background-color: #0c66e4 !important;
        }
      }

      .date-title {
        .head-text {
          font-size: 14px;
          color: #172b4d;

          &.blue-text {
            color: #0c66e4;
          }
        }
      }

      .custom-checkbox:has(.checkbox-item input:checked) {
        background-color: rgba(245, 246, 248, 1);
      }

      .item {
        // padding: 11px 10px 11px 44px;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        // border-bottom: 1px solid #e4e6ea;
        font-family: "Roboto", sans-serif;
        &.single-select {
          padding: 11px 10px 11px 16px !important;
        }

        &:hover {
          // background-color: rgba(245, 246, 248, 1);
        }
      }

      .accordion-header {
        display: flex;
        margin-top: -2px;
        justify-content: space-between;
        border-top: 1px solid rgba(245, 246, 248, 1);
        padding: 6px 0px 4px 21px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
      }
    }

    &.dropdown {
      &.active {
        button {
          font-family: "Roboto", sans-serif;

          svg {
            transform: rotate(-180deg);
          }

          border-color: #0c66e4;
          color: #44546f;
          background-color: transparent;
        }

        .menu {
          display: block;

          .item {
            // border-bottom: none;

            h3 {
              font-size: 14px;
              font-weight: 400;
              color: #44546f;
              margin-top: 0;
              font-family: "Roboto", sans-serif;
            }

            &.checkeditem {
              // background-color: red;
            }

            /* The container */
            &.custom-checkbox {
              position: relative;
              cursor: pointer;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              margin-bottom: 0;
              &.single-select {
                border-left: 2px solid #fff;
              }
              &.selected {
                border-left: 2px solid #0c66e4;
              }
            }

            /* Hide the browser's default checkbox */
            &.custom-checkbox .checkbox-item input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
            }

            /* Create a custom checkbox */
            .checkmark {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 22px;
              height: 12px;
              width: 12px;
              background-color: transparent;
              border: 2px solid rgba(9, 30, 66, 0.14);
              border-radius: 3px;
            }

            /* On mouse-over, add a grey background color */
            &.custom-checkbox:hover input ~ .checkmark {
              // background-color: #ccc;
            }

            /* When the checkbox is checked, add a blue background */
            &.custom-checkbox .checkbox-item input:checked ~ .checkmark {
              background-image: url("../Assets/Icons/dropdown-checkbox.svg");
              background-repeat: no-repeat;
              width: 12px;
              height: 12px;
              border: none;
            }

            /* Create the checkmark/indicator (hidden when not checked) */
            .checkmark:after {
              content: "";
              position: absolute;
              display: none;
            }

            /* Show the checkmark when checked */
            &.custom-checkbox .checkbox-item input:checked ~ .checkmark:after {
              display: block;
            }

            /* Style the checkmark/indicator */
            &.custom-checkbox .checkbox-item .checkmark:after {
              background: #0c66e4;
              left: -22px;
              height: 41px;
              width: 2px;
              top: -14px;
              // border: solid white;
              // border-width: 0 3px 3px 0;
              // -webkit-transform: rotate(45deg);
              // -ms-transform: rotate(45deg);
              // transform: rotate(45deg);
            }

            svg {
              margin-right: 5px;
            }

            &.divider {
              border-bottom: 1px solid #e4e6ea;
              padding: 0;
            }

            svg {
              margin-left: 5px;
              margin-right: 9px;
            }
          }
        }
      }
    }
  }
}

.rmdp-input {
  // padding-left: 11px;
  height: 36px !important;
  font-family: "Roboto";
  border: 2px solid rgba(9, 30, 66, 0.14);
  border-radius: 3px;
  color: #44546f;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}
.rmdp-input:focus {
  background-color: transparent !important;
  border: 2px solid #0c66e4 !important;
}
.rmdp-input:active {
  background-color: transparent !important;
  border: 2px solid #0c66e4 !important;
}

.rmdp-input::placeholder {
  color: rgba(191, 191, 191, 0.87) !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
  padding-left: 3px !important;
}

.date-picker-people {
  .rmdp-input::placeholder {
    color: #dadada !important;
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    padding-left: 3px !important;
  }
}

.wo-date-picker {
  .rmdp-container {
    .rmdp-input:active {
      background-color: transparent !important;
      border: 2px solid #0c66e4 !important;
    }
  }

  .rmdp-input::placeholder {
    color: #44546f !important;
  }
}

.date-picker-image {
  position: relative;
  right: 34px;

  @media (max-width: 1880px) {
    left: -38px;
    right: 0px;
    // top: -30px;
  }
}

.datepicker__title {
  position: absolute;
  top: 360px;
  margin-left: 20px;
  font-weight: 400;
}

.btn-datepicker {
  border-radius: 3px;
  padding: 7px 12px;
  font-size: 14px;
  font-style: normal;
  margin-bottom: 20px;
  font-weight: 500;
  line-height: 20px;
  background-color: #fff;
  border: none;
  &.blue {
    background-color: #0074d9 !important;
    color: #fff !important;

    &:hover {
      background-color: #468cf1 !important;
      color: #fff !important;
      border-color: transparent !important;
    }
  }
  &:hover {
    background-color: #f5f6f8 !important;
  }
}

.ui.dimmer {
  background-color: rgba(23, 43, 77, 0.2) !important;
}

.ui.modal {
  // border-radius: 8px !important;
  background: #fff !important;
  box-shadow: 0px 10px 15px 3px rgba(23, 43, 77, 0.1) !important;
  padding: 17px !important;
  .actions {
    background: #fff !important;
    border-top: none !important;
    text-align: center !important;
    padding: 8px !important;
    .button-transparent {
      border-radius: 3px;
      border: 1px solid #0c66e4;
      background: #e9f2ff;
      color: #0c66e4;
      text-align: center;
      /* .Internal/Button/Button Label */
      font-family: "Roboto";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      width: 117px !important;
      padding: 5px !important;
      &:hover {
        background-color: #0c66e4 !important;
        color: #e9f2ff !important;
      }
    }
  }

  .content {
    padding: 1.2rem 1.5rem;
  }
}
// Media queries

.ui.tiny.modal {
  width: 514px;
  border-radius: 0px !important;
  // height: 191px !important;
}

@media only screen and (min-width: 1200px) {
  .ui.tiny.modal {
    width: 514px !important;
    border-radius: 0px !important;
    // height: 191px !important;

    .content {
      padding: 1.2rem 1.5rem;
    }
    // padding: 16px !important;
  }
}

@media only screen and (max-width: 768px) {
  .ui.tiny.modal {
    width: 514px;
    border-radius: 0px !important;
    // height: 191px !important;

    .content {
      padding: 1.2rem 1.5rem;
    }
    // padding: 16px !important;
  }
}
@media only screen and (max-width: 576px) {
  .ui.tiny.modal {
    width: 95% !important;
    border-radius: 0px !important;
    height: auto !important;
    // padding: 16px !important;

    .content {
      padding: 1.2rem 1.5rem;
    }
  }
}

//        Custom Pagination CSS  (Start)       //
.custom-pagination {
  margin-top: 36px;
  max-width: fit-content;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #172b4d;
  .navigation-arrow {
    display: flex;
    cursor: pointer;
    min-width: 32px;
    min-height: 32px;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #091e4224 !important;
    }
    &.disabled {
      svg {
        path {
          fill: #788ca5 !important;
        }
      }
    }
  }
  .current-page-detail {
    margin: 0px 10px;
    padding: 10px 15px;
    background-color: #e9f2ff;
    border-radius: 5px;
    color: #0c66e4;
  }

  .total-page-detail {
    margin: 0px 10px;
  }
}
//        Custom Pagination CSS  (End)       //

.ui.input > input {
  border-radius: 3px !important;
  border: 2px solid rgba(9, 30, 66, 0.14) !important;
  background: #fff !important;
  line-height: 24px !important;
  font-family: "Roboto" !important;
  padding: 3px 8px !important;
  color: #44546f !important;
}

.direction-rtl {
  .ui.input > input {
    font-family: "Noto Kufi Arabic" !important;
  }
}

.form-button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 50px;

  .form-action-button {
    border-radius: 3px;
    padding: 7px 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    background-color: #fff;
    border: none;

    .spinner-border {
      padding: 6px !important;
    }

    &.light-blue {
      border-radius: 3px;
      border: 2px solid #0c66e4;
      background: #e9f2ff;
      color: #0c66e4;
      &:hover {
        background-color: #0c66e4 !important;
        color: white;
      }
    }

    &.blue {
      background-color: #0c66e4 !important;
      color: #fff;
      &:hover {
        background-color: #0a55bd !important;
      }
    }
    &.dashboard {
      background: #f5f6f8;
      font-weight: 400;
      border: 2px solid #091e4224;
      &:hover {
        background-color: #091e4224 !important;
      }
    }
    &:hover {
      background-color: #f5f6f8 !important;
    }
  }
}
.w-100 {
  width: 100% !important;
}

.item-button-icon-container {
  width: 32px;
  height: 32px;
  background-color: #e9f2ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-add-option-text {
  position: relative;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: #0c66e4;
}

.custom-menu-dropdown {
  .dropdown {
    height: 100%;
    // font-family: 'Roboto', sans-serif;
    min-width: 136px;
    // :hover{
    //   color: white;
    // }
    .option-toggle {
      height: 100%;
      width: 100%;
      font-weight: 500;
      background-color: #0c66e4;
      border-radius: 3px;
      font-size: 14px;
      padding: 5px 12px;
      &.inspection-option-dropdown {
        width: max-content !important;
        background: #44546f !important;
        color: white;
        /* Body Medium 14\20 */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.25px;
        // :hover{
        //   color: white;
        // }
      }
    }

    .menu {
      display: none;
    }
  }

  .dropdown {
    .rtl {
      svg {
        margin-left: 12px !important;
      }
    }
    &.show {
      .menu {
        display: block;
        position: absolute;
        z-index: 3;
        background-color: #fff;
        width: 188px;
        box-shadow: 0px 8px 12px 0px #091e4226;
        box-shadow: 0px 0px 1px 0px #091e424f;
        top: 100%;
        margin-top: 5px;

        .item {
          padding: 10px 10px 10px 16px;
          font-size: 14px;
          cursor: pointer;

          &.divider {
            border-bottom: 1px solid #e4e6ea;
            padding: 0;
          }

          svg {
            margin-right: 12px;
          }
        }
        :hover {
          color: black;
        }
      }
    }
  }
}
// .new-listing-container .new-listing-filter .ui.input > input{
//   :focus{
//     // border-color: blue;
//     border:2px solid blue !important  ;
//   }
// }
.action-custom-menu-dropdown {
  .dropdown {
    height: 100%;
    // font-family: 'Roboto', sans-serif;
    min-width: auto;

    .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
    .btn.btn-primary:focus:not(.btn-text),
    .btn.btn-primary.focus:not(.btn-text) {
      background-color: transparent;
      border-color: #0c66e4;
    }

    .procedure-toggle {
      &.btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
      .btn.btn-primary:focus:not(.btn-text),
      .btn.btn-primary.focus:not(.btn-text) {
        background-color: transparent !important; // Change background color on hover
      }
    }

    // :hover{
    //   color: white;
    // }
    .option-toggle {
      height: 100%;
      width: 100%;
      font-weight: 500;
      background-color: #e9f2ff;
      border-radius: 3px;
      font-size: 14px;
      padding: 5px 7px;
      border-color: #0c66e4;

      &.inspection-option-dropdown {
        width: max-content !important;
        background: #44546f !important;
        color: white;
        /* Body Medium 14\20 */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.25px;
        // :hover{
        //   color: white;
        // }
      }
    }

    .procedure-toggle {
      height: 100%;
      width: 100%;
      font-weight: 500;
      background-color: transparent;
      border: none !important;
      font-size: 14px;
      padding: 0px;
      &.inspection-option-dropdown {
        width: max-content !important;
        background: #44546f !important;
        color: white;
        /* Body Medium 14\20 */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.25px;
        // :hover{
        //   color: white;
        // }
      }
    }

    .menu {
      display: none;
    }
  }

  .dropdown {
    .rtl {
      svg {
        margin-left: 12px !important;
      }
    }
    &.show {
      .menu {
        display: block;
        position: absolute;
        right: 0;
        z-index: 3;
        background-color: #fff;
        width: 188px;
        box-shadow: 0px 8px 12px 0px #091e4226;
        box-shadow: 0px 0px 1px 0px #091e424f;
        top: 100%;
        margin-top: 5px;

        &.procedure {
          width: 134px;

          &.labor {
            right: 18px;
            position: absolute;
            top: -36px;
          }
        }

        .item {
          padding: 9px 8px 10px 16px;
          font-size: 14px;
          text-align: justify;
          cursor: pointer;

          &.divider {
            border-bottom: 1px solid #e4e6ea;
            padding: 0;
          }

          svg {
            margin-right: 12px;
          }
        }
        :hover {
          color: black;
        }
      }
    }
  }
}

textarea {
  color: #44546f !important;
}

.log-detail-card {
  border-radius: 8px;
  border: 1px solid #e4e6ea;
  background: #f5f6f8;
  padding: 24px;
  font-size: 16px;

  &.asset-card {
    padding: 14px 24px !important;
  }
}

.blue-text {
  color: #0c66e4 !important;
}

.jptvDR:hover {
  background-color: #eeeeee !important;
}
.gfOqsJ:hover {
  background-color: #eeeeee !important;
}

// .fjKvll:hover {
//   background-color: #e9f2ff !important;
// }

.assets-modal {
  .barcode-btns {
    gap: 15px;
    button {
      border-radius: 8px;
      border: 2px solid #e4e6ea;
      font-size: 14px;
      line-height: 20px;
      color: #44546f;
      padding: 6px 4px 6px 5px !important;

      &.active {
        border: 2px solid #0c66e4;
        background-color: #e9f2ff;
        color: #0c66e4;
      }
    }
  }
  .ui.modal .content {
    padding: 1.2rem 24px !important;
  }

  .content {
    .category-input {
      .ui.input > input:focus {
        border-color: #0c66e4 !important;
      }
    }
    .ui.list {
      padding-inline: 5px;
      margin-top: -14px;

      > div {
        border: 1px solid #e4e6ea;
        border-radius: 8px;
        background: #f5f6f8;
        margin-top: 23px;
      }
    }

    .parts-listing-container {
      overflow-x: auto;
      height: 480px;

      .hgBzTD {
        justify-content: start;
        margin-left: 10px;
        flex: 0 0 18px;
        min-width: 0px;
      }
    }
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    background-color: #e9f2ff;
    padding: 10px 24px;
    border-top: 1.5px solid #0c66e4;
    border-radius: 0px !important;
    .assets-header {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      // font-family: 'Roboto';
      letter-spacing: 0.15px;
    }
    &.close-data {
      background-color: #2243b01a !important;
      border-top: 1.5px solid #000563;
      background: #2243b01a !important;
    }
    &.open {
      background-color: #0c66e433 !important;
      border-top: 1.5px solid #0c66e4;
      background: #0c66e433 !important;
    }

    &.reOpen {
      background-color: #554feb1a !important;
      border-top: 1.5px solid #554feb;
      background: #554feb1a !important;
    }
    &.green {
      background-color: rgba(79, 171, 104, 0.1) !important;
      border-top: 1.5px solid var(--green_action, #4fab68);
      background: var(--green_transparent, rgba(79, 171, 104, 0.1));
    }
    &.red {
      background-color: rgba(209, 64, 64, 0.1) !important;
      border-top: 1.5px solid var(--red_actions, #d14040);
      background: var(--red_transparent, rgba(209, 64, 64, 0.1));
    }
    &.grey {
      background-color: #f5f6f8 !important;
      border-top: 1.5px solid #788ca5;
    }
  }

  .assets-label {
    // font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.5px;
  }

  .detail-intro-link {
    color: #0c66e4;
    text-align: start;
    word-break: break-all;
    //font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .radio-text {
    // font-family: 'Roboto' !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 17px !important;
    letter-spacing: 0.25px;

    &.asset {
      line-height: 13px !important;
    }
  }

  .modal-input {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .file-label {
      color: #172b4d;
      text-align: center;
      /* Title Medium 16\24 */
      font-family: "Roboto";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: 0.15px;
    }

    .file-input {
      width: 404px !important;
    }
    .small-box {
      width: 400px;
      height: 35px;
      border-radius: 3px;
      border: 2px solid rgba(9, 30, 66, 0.14);
      text-align: center;
      line-height: 20px;

      .text-label {
        margin-left: 10px;
        margin-top: 5px;
      }
    }

    .browse-btn {
      border-radius: 3px;
      padding: 7px 12px;
      font-family: "Roboto";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      background-color: #e9f2ff;
      border: none;
      color: #0c66e4;
      border: 2px solid #0c66e4;
    }
  }
  .modal-button-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    .modal-form-action-button {
      border-radius: 3px;
      padding: 7px 12px;
      font-family: "Roboto";
      letter-spacing: 0.25px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #0c66e4;
      background-color: #fff;
      margin-left: -10px;
      border: none;

      &.purchase-order {
        padding: 5px 0px;
      }

      &.black {
        color: #172b4d;
        font-weight: 500;
      }
      &.blue {
        background-color: #0a55bd !important;
        color: #fff;
        &:hover {
          background-color: #468cf1 !important;
        }
      }
      &.dark-blue {
        background-color: #000563 !important;
        color: #e9f2ff;
        &:hover {
          background-color: #141979 !important;
        }
      }
      &.green {
        background-color: #4fab68 !important;
        color: #e9f2ff;
        &:hover {
          background-color: #83c494 !important;
        }
      }
      &.grey {
        background-color: #788ca5 !important;
        color: #e9f2ff;
        &:hover {
          background-color: #93a5bb !important;
        }
      }
      &.light-blue {
        background-color: #0c66e4 !important;
        color: #fff;
        &:hover {
          background-color: #468cf1 !important;
        }
      }
      &.purple {
        background-color: #554feb !important;
        color: #e9f2ff;
        &:hover {
          background-color: #746fe8 !important;
        }
      }

      &.red {
        background-color: #d14040 !important;
        color: #e9f2ff;
        &:hover {
          background-color: #e45757 !important;
        }
      }
      &:hover {
        background-color: #f5f6f8 !important;
      }
    }
  }
}

.procedure-modal {
  // .ui.modal .content {
  //   padding: 1.2rem 24px !important;
  // }
  // .content {
  //   .category-input {
  //     .ui.input > input:focus {
  //       border-color: #0c66e4 !important;
  //     }
  //   }
  //   .ui.list {
  //     padding-inline: 5px;
  //     margin-top: -14px;

  //     > div {
  //       border: 1px solid #e4e6ea;
  //       border-radius: 8px;
  //       background: #f5f6f8;
  //       margin-top: 23px;
  //     }
  //   }
  // }

  .header-container {
    display: flex;
    justify-content: space-between;
    background-color: #e9f2ff;
    padding: 10px 24px;
    border-top: 1.5px solid #0c66e4;
    border-radius: 0px !important;
    .assets-header {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      // font-family: 'Roboto';
      letter-spacing: 0.15px;
    }
    &.green {
      background-color: rgba(79, 171, 104, 0.1) !important;
      border-top: 1.5px solid var(--green_action, #4fab68);
      background: var(--green_transparent, rgba(79, 171, 104, 0.1));
    }
    &.close-data {
      background-color: #2243b01a !important;
      border-top: 1.5px solid #000563;
      background: #2243b01a !important;
    }
    &.red {
      background-color: rgba(209, 64, 64, 0.1) !important;
      border-top: 1.5px solid var(--red_actions, #d14040);
      background: var(--red_transparent, rgba(209, 64, 64, 0.1));
    }
    &.grey {
      background-color: #f5f6f8 !important;
      border-top: 1.5px solid #788ca5;
    }
  }

  .assets-label {
    // font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.5px;
  }

  .modal-input {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .file-label {
      color: #172b4d;
      text-align: center;
      /* Title Medium 16\24 */
      // font-family: 'Roboto';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: 0.15px;
    }

    .file-input {
      width: 404px !important;
    }
    .small-box {
      width: 400px;
      height: 35px;
      border-radius: 3px;
      border: 2px solid rgba(9, 30, 66, 0.14);
      text-align: center;
      line-height: 20px;

      .text-label {
        margin-left: 10px;
        margin-top: 5px;
      }
    }

    .browse-btn {
      border-radius: 3px;
      padding: 7px 12px;
      // font-family: 'Roboto';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      background-color: #e9f2ff;
      border: none;
      color: #0c66e4;
      border: 1px solid #0c66e4;
    }
  }
  // .modal-button-container {
  //   display: flex;
  //   justify-content: flex-end;
  //   gap: 10px;

  .modal-form-action-button {
    border-radius: 3px;
    padding: 7px 12px;
    // font-family: 'Roboto';
    letter-spacing: 0.25px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #0c66e4;
    background-color: #fff;
    margin-left: -10px;
    border: none;

    &.black {
      color: #172b4d;
      font-weight: 500;
    }
    &.blue {
      background-color: #0a55bd !important;
      color: #fff;
      &:hover {
        background-color: #468cf1 !important;
      }
    }
    &.grey {
      background-color: #788ca5 !important;
      color: #e9f2ff;
      &:hover {
        background-color: #93a5bb !important;
      }
    }
    &.light-blue {
      background-color: #0c66e4 !important;
      color: #fff;
      &:hover {
        background-color: #468cf1 !important;
      }
    }
    &.red {
      background-color: #d14040 !important;
      color: #e9f2ff;
      &:hover {
        background-color: #e45757 !important;
      }
    }
    &:hover {
      background-color: #f5f6f8 !important;
    }
  }
  // }
}
.ui.dimmer > .content {
  .text-primary {
    color: #0c66e4 !important;
  }
}
.Custom-search-dropdown {
  // min-width: 104px;
  .title-text {
    color: rgba(120, 140, 165, 1);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    font-weight: 400;
  }

  &.dashbaord {
    .dropdown {
      height: auto !important;
      background-color: #fff !important;
    }
  }
  &.location-drop {
    .dropdown {
      .menu {
        min-width: 313px !important;
        .custom-dropdown-item {
          padding: 6px 0px 5px 10px;
          min-height: 30px !important;
          border-bottom: 0px solid #e4e6ea !important;

          .custom-dropdown-text {
            margin-left: 10px !important;
            margin-right: 10px !important;
            text-align: start !important;
          }
          // .avatar {
          //   width: 20px !important;
          //   height: 20px !important;
          // }
        }
        .dropdown-search-input {
          input {
            min-width: 283px !important;
          }
        }
      }
    }
  }

  .dropdown {
    height: 100%;
    // font-family: 'Roboto', sans-serif;

    &.blue-border {
      button {
        height: 100%;

        font-weight: 400;
        border-radius: 3px;
        font-size: 14px;
        padding: 5px 6px;
        border: 2px solid #0c66e4 !important;
        background-color: transparent;
        color: #44546f;
        // font-family: 'Roboto', sans-serif;

        &:hover {
          border-color: #0c66e4 !important;
          color: #44546f !important;
          background-color: transparent !important;
        }
      }
    }

    button {
      height: 100%;
      width: 100%;
      font-weight: 400;
      border-radius: 3px;
      font-size: 14px;
      padding: 5px 6px;
      border: 2px solid rgba(9, 30, 66, 0.14);
      background-color: transparent;
      color: #44546f;
      // font-family: 'Roboto', sans-serif;

      &:hover {
        border-color: #0c66e4 !important;
        color: #44546f !important;
        background-color: transparent !important;
      }
    }

    .menu {
      max-width: 260px;
      max-height: 390px;
      overflow-y: scroll;
      display: none;
      position: absolute;
      z-index: 1;
      background-color: #fff;
      width: 260px;
      border: 1px solid #e4e6ea;
      top: 100%;
      margin-top: 5px;

      .dropdown-search-input {
        // width: 160px;

        input {
          padding-left: 45px !important;
          border-radius: 5px !important;
          max-width: 231px !important;
          min-width: 231px !important;
          color: #172b4d !important;
          /* Body Medium 14\20 */
          // font-family: 'Roboto';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px !important; /* 142.857% */
          letter-spacing: 0.25px;

          border: 2px solid #0c66e4 !important;
        }
      }

      .custom-checkbox:has(.checkbox-item input:checked) {
        background-color: rgba(245, 246, 248, 1);
      }

      .custom-dropdown-item {
        cursor: pointer;
        min-height: 72px !important;
        padding: 16px;
        .avatar {
          width: 20px !important;
          height: 20px !important;
        }
        .custom-dropdown-text {
          color: #172b4d;
          text-align: start;
          /* Title Medium 16\24 */
          // font-family: 'Roboto';
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
          letter-spacing: 0.15px;
        }

        &.selected {
          background-color: #e9f2ff !important;
        }
        &:hover {
          background-color: #e9f2ff !important;
        }
      }

      .item {
        // padding: 11px 10px 11px 44px;
        font-size: 14px;
        cursor: pointer;
        // display: flex;
        align-items: center;
        border-bottom: 1px solid #e4e6ea;
        // font-family: 'Roboto', sans-serif;

        &:hover {
          // background-color: rgba(245, 246, 248, 1);
        }
      }
    }

    &.dropdown {
      &.active {
        button {
          // font-family: 'Roboto', sans-serif;

          svg {
            transform: rotate(-180deg);
          }

          border-color: #0c66e4;
          color: #44546f;
          background-color: transparent;
        }

        .menu {
          display: block;

          .item {
            // border-bottom: none;

            h3 {
              font-size: 14px;
              font-weight: 400;
              color: #44546f;
              margin-top: 0;
              font-family: "Roboto", sans-serif;
            }

            &.checkeditem {
              // background-color: red;
            }

            /* The container */
            &.custom-checkbox {
              position: relative;
              cursor: pointer;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              margin-bottom: 0;
            }

            /* Hide the browser's default checkbox */
            &.custom-checkbox .checkbox-item input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
            }

            /* Create a custom checkbox */
            .checkmark {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 22px;
              height: 12px;
              width: 12px;
              background-color: transparent;
              border: 2px solid rgba(9, 30, 66, 0.14);
              border-radius: 3px;
            }

            /* On mouse-over, add a grey background color */
            &.custom-checkbox:hover input ~ .checkmark {
              // background-color: #ccc;
            }

            /* When the checkbox is checked, add a blue background */
            &.custom-checkbox .checkbox-item input:checked ~ .checkmark {
              background-image: url("../Assets/Icons/dropdown-checkbox.svg");
              background-repeat: no-repeat;
              width: 12px;
              height: 12px;
              border: none;
            }

            /* Create the checkmark/indicator (hidden when not checked) */
            .checkmark:after {
              content: "";
              position: absolute;
              display: none;
            }

            /* Show the checkmark when checked */
            &.custom-checkbox .checkbox-item input:checked ~ .checkmark:after {
              display: block;
            }

            /* Style the checkmark/indicator */
            &.custom-checkbox .checkbox-item .checkmark:after {
              background: #0c66e4;
              left: -22px;
              height: 41px;
              width: 2px;
              top: -14px;
              // border: solid white;
              // border-width: 0 3px 3px 0;
              // -webkit-transform: rotate(45deg);
              // -ms-transform: rotate(45deg);
              // transform: rotate(45deg);
            }

            svg {
              margin-right: 5px;
            }

            &.divider {
              border-bottom: 1px solid #e4e6ea;
              padding: 0;
            }

            svg {
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
}

.ui.modal {
  padding: 0px 0px 24px 0px !important;

  &.delete-result-modal {
    border-radius: 8px !important;

    &.fail {
      border-radius: 8px;
      border: 1px solid var(--red_actions, #d14040);
      background: #fff;

      box-shadow: 0px 10px 15px 3px rgba(23, 43, 77, 0.1);
    }

    &.success {
      border-radius: 8px;
      border: 1px solid var(--green_action, #4fab68);
      background: #fff;
      box-shadow: 0px 10px 15px 3px rgba(23, 43, 77, 0.1);
    }
  }
}

.pill {
  border-radius: 10px;
  border: 1px solid #e9f2ff;
  background: #e9f2ff;
  color: #0c66e4;
  text-align: start;
  // font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 3px 5px;
  min-width: 87px;
  &.kanban {
    padding: 1px 4px !important;
    min-width: 20px;
  }

}
.rmdp-calendar {
  .rmdp-header-values {
    color: #172b4d;
    text-align: center;
    /* .Internal/Calendar/.Calendar - Month */
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
  }
  .rmdp-week-day {
    // margin: 10px;
    color: #44546f;
    font-weight: 700;

    // font-family: 'SF Pro Text';
    // font-size: 11px;
    // font-style: normal;
    // font-weight: 700;
    // line-height: 16px;
  }
  .rmdp-day {
    height: 35px !important;
    width: 38px !important;
    color: #44546f;
    text-align: center;
    /* UI Text */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */

    span:hover {
      background-color: hsl(215, 90%, 47%) !important;
    }
    &:hover {
      background-color: #0c66e4 !important;
    }

    &.rmdp-disabled {
      color: #8798ad !important;
    }
  }
  .rmdp-range {
    background-color: #e9f2ff !important;
    color: #0c66e4 !important;
    box-shadow: none;

    &.start {
      border-radius: 3px !important;
    }
    &.end {
      border-radius: 3px !important;
    }
  }
  .rmdp-today {
    span {
      background-color: #0c66e4 !important;
    }
  }
}

.workorder-intro-label {
  margin-bottom: 0px;
  color: #788ca5;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;

  &.work-order-label {
    width: 75px !important;
  }
  &.set-label {
    min-width: 80px !important;
  }
  &.work-request-label {
    min-width: 79px !important;
  }
  &.po-label {
    min-width: 148px !important;
  }
  &.part-label {
    min-width: 96px !important;
  }
  &.asset-label {
    min-width: 162px !important;
  }
  &.people-label {
    min-width: 162px !important;
  }
  &.team-label {
    min-width: 132px !important;
  }
  &.attachment-label {
    min-width: 99px !important;
  }
  &.personal-label {
    min-width: 153px !important;
  }
  &.customer-label {
    min-width: 99px !important;
  }
  &.customer-label1 {
    min-width: 99px !important;
  }

  &.vendor-label {
    min-width: 131px !important;
  }
  &.vendor-label1 {
    min-width: 155px !important;
  }
  &.location-label {
    min-width: 93px !important;
  }

  &.location-label2 {
    min-width: 70px !important;
  }

  &.grey {
    font-size: 14px !important;
    color: #788ca5 !important;
  }
}

.workorder-intro-value {
  margin-left: 16px;
  margin-right: 16px;
  color: #172b4d;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  &.parts {
    margin-left: 11px !important;
    margin-right: 11px !important;
  }

  &.grey-container {
    margin-left: 8px;
    background: #f5f6f8;
    border-radius: 6px;
    padding: 9px 6px 5px 0px;
    width: 145px !important;
  }

  &.grey {
    font-size: 12px !important;
    color: #788ca5 !important;
  }

  .detail-intro-link {
    color: #0c66e4 !important;
    text-align: start;
    word-break: break-all;
    //font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    cursor: pointer;
  }

  &.detail-intro-link {
    color: #0c66e4 !important;
    text-align: center;
    word-break: break-all;
    //font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .people-activated {
    font-size: 14px !important;
  }
}
.people-circle-image {
  position: absolute;
  background: rgba(23, 43, 77, 0.6);
  border-radius: 0% 0% 50px 50px;
  border-color: rgba(23, 43, 77, 0.6);
  top: 39px;
  left: 5px;
  width: 80px;
  height: 41px;

  &.rtl-people {
    right: 5px;
  }

  .edit-container {
    position: absolute;
    top: 6px;
    color: white;
    border-radius: 50% 50% 0 0;
    margin-left: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;

    &.rtl-edit {
      margin-right: 5px;
    }
  }
}

.assets-modal .assignee-data .field .ui.dropdown.selection .label {
  height: auto;
}

.custom-dropdown-menu {
  .menu {
    max-height: 150px !important;
  }
}

.custom-dropdown-menu2 {
  .menu {
    max-height: 172px !important;
  }
  padding: 6px 10px !important;
}
.ui.basic.buttons {
  border: 0 !important;
  text-shadow: none !important;
  gap: 10px !important;
  .day-btn {
    padding: 8px;
    font-size: 14px;
    display: flex;
    color: #44546f;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    border-radius: 5px !important;
  }
  &.view-btn {
    gap: 0px !important;
  }
}

.ui.basic.buttons .button {
  box-shadow: 5;
}

.ui.darkgrey.label {
  background-color: #788ca5 !important;
  border-color: #788ca5 !important;
  color: #fff !important;
}
.ui.darkblue.label {
  background-color: #000563 !important;
  border-color: #000563 !important;
  color: #fff !important;
}

.ui.basic.buttons .button {
  border-radius: 6px !important;
  box-shadow: none;
  border: 1px solid rgba(9, 30, 66, 0.14) !important;

  &.week-btn {
    color: var(--strong_grey, #44546f);
    text-align: center;
    /* Body Medium 14\20 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    border-radius: 8px 0px 0px 8px !important;
    padding: 7px 4px 4px 4px;

    &.active {
    }
  }
  &.day-btn {
    color: var(--strong_grey, #44546f);
    text-align: center;
    /* Body Medium 14\20 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    border-radius: 8px 8px 8px 8px !important;
    padding: 2px 8px 2px 9px;
  }
  &.shift-btn {
    color: var(--strong_grey, #44546f);
    text-align: center;
    min-height: 32px;
    min-width: 85px;
    /* Body Medium 14\20 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    border-radius: 8px 8px 8px 8px !important;
    padding: 2px 8px 2px 9px;
  }
}

.repeat-container-flex {
  gap: 20px !important;
  display: flex !important;
  .field {
    min-height: 54px !important;
  }
}

.common-text {
  font-size: 16px;
  line-height: 24px;
}

.weekly-btns {
  .field {
    min-height: 54px !important;
  }
}
.repeat-container {
  height: 104px !important;
  .field {
    min-height: 54px !important;
  }
}

.repeat-everly {
  background-color: #f5f6f8;
  width: 139px !important;
}

.week-dropdown {
  border-radius: 4px;
  border: 1px solid #e0e1e2;
  padding: 9px;
  background: #f5f6f8;
}
.side-dropdown {
  gap: 20px !important;
  .work-input {
    input {
      padding: -3px !important;
      border-radius: 8px !important;
      width: 55px !important ;
      padding: 0 px !important;
      padding-left: 12px !important;
      background-color: #f5f6f8 !important;
    }
  }
}

.workorder-date-input {
  background: #f5f6f8 !important;
}

.basic-btn {
  width: 4px !important;
}

.ui.dropdown {
  > .text {
    color: #44546f;
    /* Body Medium 14\20 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    & > .image {
      margin: auto;
      margin-top: -0px !important;
    }
  }
  .menu > .item {
    > .text {
      color: #44546f;
      /* Body Medium 14\20 */
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.25px;
    }
    > .image {
      margin: auto 10px !important;
    }
  }
  > .label {
    color: #44546f !important;
    /* Body Medium 14\20 */
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    > .delete.icon {
      color: #788ca5 !important;
      opacity: 1 !important;
    }
  }
}

.hold-button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  .form-action-button {
    border-radius: 3px;
    padding: 7px 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    background-color: #fff;
    border: none;

    &.light-blue {
      border-radius: 3px;
      border: 2px solid #0c66e4;
      background: #e9f2ff;
      color: #0c66e4;
      &:hover {
        background-color: #0c66e4 !important;
        color: white;
      }
    }
    &.grey {
      background-color: #788ca5 !important;
      color: #e9f2ff;
      font-weight: 500;
      &:hover {
        background-color: #93a5bb !important;
      }
    }
    &.blue {
      background-color: #0c66e4 !important;
      color: #fff;
      &:hover {
        background-color: #468cf1 !important;
      }
    }
    &:hover {
      background-color: #f5f6f8 !important;
    }
  }
}

.send-action-button {
  border-radius: 3px;
  padding: 6px 14px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  background-color: #fff;
  border: none;
  &.blue {
    background-color: #0c66e4 !important;
    color: #fff;
    &:hover {
      background-color: #468cf1 !important;
    }
  }
  &.red {
    background-color: #d14040 !important;
    color: #e9f2ff;
    &:hover {
      background-color: #e45757 !important;
    }
  }
  &:hover {
    background-color: #f5f6f8 !important;
  }
}

.approval-button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  .modal-form-action-button {
    border-radius: 3px;
    padding: 8px 12px;
    letter-spacing: 0.25px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    background-color: #fff;
    border: none;

    &.blue {
      background-color: #0c66e4 !important;
      color: #fff;
      &:hover {
        background-color: #468cf1 !important;
      }
    }

    &.decline {
      background-color: transparent;
      color: #172b4d;
      &:hover {
        background-color: transparent !important;
      }
    }
    &.red {
      background-color: #d14040 !important;
      color: #fff;
      &:hover {
        background-color: #e45757 !important;
      }
    }
    &:hover {
      background-color: #f5f6f8 !important;
    }
  }
}

.work-order-link {
  color: #0c66e4;
  text-align: start;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-decoration-line: underline;
  cursor: pointer;
}

.rmdp-deactive {
  color: #8798ad !important;
}

.views {
  .week-filter {
    gap: 24px !important;
  }
  .list-view-option {
    display: flex;
    gap: 10px;
    margin-top: 8px;
    // flex-wrap: wrap;
    .single-view:first-child {
      white-space: nowrap;
    }
    .single-view {
      text-align: center;

      // font-family: 'Roboto';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: 0.15px;

      justify-content: center;
      align-items: center;

      a {
        color: #172b4d;
      }

      a.active {
        background-color: #4fab68;
        border-radius: 5px;

        padding: 4px 16px;
        color: #fff;
        border: 1px solid;
      }
    }
  }
}

.list-view-option-setting {
  display: flex;
  gap: 10px;
  margin-top: 8px;
  // flex-wrap: wrap;
  .single-view-setting:first-child {
    white-space: nowrap;
  }
  .single-view-setting {
    text-align: center;

    // font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;

    justify-content: center;
    align-items: center;

    a {
      color: #172b4d;
    }

    a.active {
      background-color: #4fab68;
      border-radius: 5px;

      padding: 4px 16px;
      color: #fff;
      border: 1px solid;
    }
  }
}

.fc {
  .day-container {
    margin-top: 3px;
    border-radius: 10px;
    .fc-event-main {
      background: white;
    }

    &.open {
      border: 1px solid var(--blue, #0c66e4);
      // background: rgb(233, 242, 255);
      background-image: url("../Assets/Icons/open_bg.png");
    }
    &.onHold {
      border: 1px solid var(--red_actions, #d14040);
      // background: var(--red_transparent, rgba(209, 64, 64, 0.1));
      background-image: url("../Assets/Icons/on_hold_bg.png");
    }

    &.inProgress {
      border: 1px solid var(--yellow, #e2b203);
      // background: var(--yellow_transparent, rgba(226, 178, 3, 0.1));
      background-image: url("../Assets/Icons/in_progress_bg.png");

      .event-container-new {
        p {
          color: #172b4d !important;
        }
      }
    }
    &.inReview {
      border: 1px solid #172b4d;
      // background: rgb(226, 242, 231);
      background-image: url("../Assets/Icons/in_review.png");
      .event-container-new {
        p {
          color: #172b4d !important;
        }
      }
    }
    &.completed {
      border: 1px solid var(--green_action, #4fab68);
      // background: rgb(226, 242, 231);
      background-image: url("../Assets/Icons/completed_bg.png");
      .event-container-new {
        p {
          color: #172b4d !important;
        }
      }
    }
  }

  .week-container {
    margin-top: 3px;
    border-radius: 10px;
    .fc-event-main {
      background: white;
    }
    &.open {
      border: 1px solid var(--blue, #0c66e4);
      background: rgb(233, 242, 255);
      .event-container-new {
        p {
          color: #172b4d !important;
        }
      }
    }
    &.onHold {
      border: 1px solid var(--red_actions, #d14040);
      background: var(--red_transparent, rgba(209, 64, 64, 0.1));
      .event-container-new {
        p {
          color: #172b4d !important;
        }
      }
    }

    &.inProgress {
      border: 1px solid var(--yellow, #e2b203);
      background: var(--yellow_transparent, rgba(226, 178, 3, 0.1));
      .event-container-new {
        p {
          color: #172b4d !important;
        }
      }
    }
    &.completed {
      border: 1px solid var(--green_action, #4fab68);
      background: rgb(226, 242, 231);
      .event-container-new {
        p {
          color: #172b4d !important;
        }
      }
    }
  }
  .event-container-new {
    gap: 15px;
    // width: 75%;
    padding: 8px 12px;
    p:nth-child(1) {
      color: #172b4d;
      /* Body Medium 14\20 */
      font-family: "Roboto";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.25px;
      // white-space: normal;
      // word-wrap: break-word;
      // word-break: break-all;
      margin-bottom: 3px !important;
    }
    p:nth-child(2) {
      color: #788ca5;
      /* Body Small 12\16 */
      font-family: "Roboto";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.4px;
      // white-space: normal;
      // word-wrap: break-word;
    }
  }
  .icon-container {
    // width: 30%;
    padding: 8px 12px 8px 3px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-self: end;

    .schedual-icon {
      margin-left: 2px;
    }
  }

  .fc-daygrid-event {
    min-height: 54px;
    border-radius: 10px;
    color: #172b4d;
    /* Body Medium 14\20 */
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    margin-top: 5px;
    .fc-event-main {
      // margin-top: -1px;
      // border-bottom: 2px solid #f0e9e9 !important;
      min-height: 54px;
      .fc-event-main-frame {
        min-height: 54px;
        justify-content: center;
        align-items: center;
        .fc-event-time {
          margin-left: 3px;
          min-width: 40px;
          text-align: center;
        }
        .fc-event-title-container {
          text-align: center;
          .fc-event-title {
            white-space: normal;
            word-wrap: break-word;
          }
        }
      }
    }
  }
  .fc-col-header-cell {
    .fc-scrollgrid-sync-inner {
      background-color: #f5f6f8;
      color: #172b4d;
      text-align: center;
      /* .Internal/Atlassian Navigation/.Search text */
      // font-family: "Roboto";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px; /* 228.571% */
      > a {
        color: #172b4d;
      }
    }
  }
  .fc-daygrid-day-number {
    color: #172b4d;
    text-align: center;

    /* .Internal/Atlassian Navigation/.Search text */
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 228.571% */
  }
  .fc-timegrid-slot {
    .fc-timegrid-slot-label-frame {
      .fc-timegrid-slot-label-cushion {
        color: #172b4d;
        text-align: center;

        /* .Internal/Atlassian Navigation/.Search text */
        font-family: "Roboto";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 228.571% */
      }
    }
  }
  .fc-timegrid-event {
    border: none;

    margin-top: 2px !important;
    color: #172b4d;
    /* Body Medium 14\20 */
    // background-color: rgb(254 244 210);
    background-color: transparent !important;
    border-radius: 16px !important;
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    box-shadow: none;
    // .fc-event-main {
    //   margin-top: 1px;
    //   border-bottom: 1px solid #d5cece;
    //   background: white;
    //   color: rgb(23, 43, 77);
    //   padding: 1px 1px 0;
    // }
  }
}
.fc-timegrid-event-harness > .fc-timegrid-event {
  bottom: 4px !important;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  //  max-height: 188px !important;
  //  overflow-x: hidden !important;
}

// .fc .fc-timegrid-slot {
//   background: white !important ;
// }

// .fc .fc-daygrid-day-frame {
//   background: white !important;
// }

.map-button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  .map-modal-form-action-button {
    border-radius: 3px;
    padding: 7px 12px;
    font-family: "Roboto";
    letter-spacing: 0.25px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    background-color: #fff;
    border: none;
    &.blue {
      background-color: #0c66e4 !important;
      color: #fff;
      &:hover {
        background-color: #468cf1 !important;
      }
    }
    &.red {
      background-color: #d14040 !important;
      color: #e9f2ff;
      &:hover {
        background-color: #e45757 !important;
      }
    }
    &:hover {
      background-color: #f5f6f8 !important;
    }
  }
}

.Toastify__toast {
  border-radius: 8px;

  background: var(--white, #fff);
  box-shadow: 0px 8px 12px 0px rgba(9, 30, 66, 0.15),
    0px 0px 1px 0px rgba(9, 30, 66, 0.31);
  padding: 10px;
  min-height: 73px;
  min-width: 309px;
}
.Toastify__toast--error {
  border: 1px solid #d14040;
}
.Toastify__toast--success {
  border: 1px solid #4fab68;
}
.Toastify__toast-body {
  margin-left: 10px;
  > div:last-child {
    color: #172b4d;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
}

.body {
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #788ca5;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #788ca5;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.img-circle {
  border-radius: 50%;
  span {
    left: 0;
    text-align: center;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    bottom: 0;
    padding: 20px 0;
  }
}
img.img-circle {
  background: black;
}

.img-thumbnail {
  border-radius: 0;
  display: inline-block;
  background: #dbdbdb;
  padding: 20px;
}
.img-thumbnail > div {
  display: inline-block;
  overflow: hidden;
  height: 140px;
  border: 5px solid #fff;
  -webkit-box-shadow: 0 2px 0 2px #333;
  box-shadow: 0 2px 0 2px #333;
  border-radius: 50%;
}

.topbar {
  .header-date {
    border-radius: 3px;
    background: #e9f2ff;
    padding: 8px 8px 8px 5px;
    // font-family: 'Roboto';
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 20px;
    &.rtl-date {
      padding: 8px 5px 8px 10px;
    }

    .day-text {
      color: #788ca5;
      margin-right: 5px;
    }
  }
  .svg-icon.svg-icon-xl svg {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }
}
.wrapper-desktop-logo {
  display: flex;
  align-items: center;
  gap: 8px;
  // cursor: pointer;
  svg {
    height: 24px;
    width: 24px;
  }
  .logo-icon {
    font-weight: 500;
    font-size: 18px;
    // font-family: roboto;
  }
}
@media screen and (max-width: 991px) {
  .wrapper-desktop-logo {
    display: none;
  }
  .mb-2-css {
    margin-bottom: 5px;
  }
}
.new-listing-filter {
  .filters {
    .views {
      flex-wrap: wrap;
    }
  }
}
.header-fixed.subheader-fixed.subheader-enabled .wrapper {
  padding-top: 50px;
}

#kt_header {
  border-bottom: 1px solid #e4e6ea;
}
.field {
  .ui.multiple.selection {
    padding: 3.5px 4px !important;
    .ui.label {
      margin: 3px 5px !important;
    }
  }
}

.image-responsive {
  @media screen and (min-width: 1500px) {
    width: 650px !important;
  }
}

.dashboard-container {
  background-color: #f9fcff;
  .dashboard-filters {
    gap: 20px;
    display: flex;
    justify-content: space-between;
    align-items: start;

    .table-searchbar {
      position: relative !important;

      .ui.input {
        width: -webkit-fill-available !important;
      }
    }

    .table-searchbar > svg {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 18px;
      z-index: 1;
    }

    .ui.icon.input > i.icon {
      display: none;
    }
  }

  .wo-label-container {
    margin-top: 30px;
    background-color: rgba(233, 242, 255, 1);
    padding: 10px 24px 10px 24px;
    border-radius: 5px;

    span {
      font-size: 22px;
      line-height: 28px;
      font-weight: 400;
      font-family: "Roboto";
    }
  }

  .graph-container {
    background-color: transparent;
    .completion-column {
      .completion-column-data {
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid rgba(228, 230, 234, 1);

        .hover-div {
          padding: 20px 6px;
          border-radius: 4px;
          margin-inline: 13px;
          &:hover {
            background-color: rgba(245, 246, 248, 1);
          }
        }
        .completion-cotainer {
          padding: 15px 10px 13px 18px;
          border-radius: 10px;

          .completion-title {
            .title {
              font-size: 16px;
              line-height: 24px;
              font-weight: 500;
              // font-family: "Roboto";
              letter-spacing: 0.15px;
            }
          }
        }

        .completion-status-part {
          overflow: hidden;
          .wo-data-container {
            .wo-data-status {
              .status-container {
                &.assignee {
                  overflow: auto;
                  overflow-x: hidden;
                  height: 372px;
                  &.h-500 {
                    height: 500px !important;
                  }
                }
                .item-value {
                  .label-text {
                    font-size: 16px;
                    line-height: 28px;

                    color: rgba(138, 146, 166, 1);

                    p {
                      color: rgba(35, 45, 66, 1);
                      font-weight: 500;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.graph-container {
  background-color: transparent;
  .completion-column {
    .completion-column-data {
      background-color: #fff;
      border-radius: 8px;
      border: 1px solid rgba(228, 230, 234, 1);
      &.no-border {
        border: none;
      }

      .hover-div {
        padding: 20px 6px;
        border-radius: 4px;
        margin-inline: 13px;
        &:hover {
          background-color: rgba(245, 246, 248, 1);
        }
      }
      .completion-cotainer {
        padding: 15px 10px 13px 18px;
        border-radius: 10px;

        .completion-title {
          .title {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            // font-family: "Roboto";
            letter-spacing: 0.15px;
          }
        }
      }

      .completion-status-part {
        overflow: hidden;
        .wo-data-container {
          .wo-data-status {
            .status-container {
              &.assignee {
                overflow: scroll;
                overflow-x: hidden;
                height: 347px;

                &.h-500 {
                  height: 500px !important;
                }
              }
              .item-value {
                .label-text {
                  font-size: 16px;
                  line-height: 28px;

                  color: rgba(138, 146, 166, 1);

                  p {
                    color: rgba(35, 45, 66, 1);
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.dropdown-tooltip {
  position: absolute;
  top: 40px;
  left: 8px;
  width: 280px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
  padding: 5px;

  &.people {
    left: -50px;
  }

  .table-searchbar {
    position: relative !important;

    .ui.input {
      width: -webkit-fill-available !important;
    }

    input {
      padding-left: 45px !important;
      border-radius: 5px !important;
      max-width: 251px !important;
      min-width: 251px !important;
      color: #172b4d !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px !important;
      letter-spacing: 0.25px;
      border: 2px solid #0c66e4 !important;
    }

    > svg {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 18px;
      z-index: 1;
    }
  }

  .dropdown-options {
    max-height: 200px;
    overflow-y: auto;

    .dropdown-item {
      min-height: 32px !important;
      padding: 8px !important;

      &.avatar-selected {
        border-left: 2px solid #0c66e4; /* Inner white border */
        background-color: rgba(228, 230, 234, 0.5) !important;
      }

      span {
        margin-left: 5px;
        font-size: 14px;
      }
      &:active {
        background-color: transparent !important;
        color: black;
      }

      &:hover {
        border-left: 2px solid #0c66e4 !important;
      }
    }
  }

  // .table-searchbar
}

.location-length {
  position: absolute;
  padding-inline: 9px;
  color: #0c66e4;
  background: #e9f2ff;
  border-radius: 2px;
  width: 27px;
  bottom: 26px;
  left: 11px;
}

.link-text-location {
  :hover {
    // position: absolute;
    background: #0c66e4;
    color: white;
    // padding: 3px 7px 1px 6px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.table-searchbar input {
  max-width: 222px !important;
}

.ui.fluid.multiple.search.selection.dropdown {
  margin-bottom: 20px;
  display: inline-block;
}
.custom-aside-menu .menu-nav .menu-item .menu-link .menu-text {
  white-space: nowrap;
}
.aside-minimize
  .aside-menu
  .menu-nav
  .menu-item.menu-item-active
  > .rtl-menu-link {
  background-color: transparent !important;
}

// .configure-icon svg {
//   height: 23px !important;
//   width: 23px !important;
//   margin-left: -2px !important;
//   max-height: 23px !important;
//   max-width: 23px !important;
// }

// .Category-dropdown,
// .Custom-search-dropdown {
//   z-index: 2;
// }

.direction-rtl {
  .location-length {
    bottom: 26px;
    left: 11px;
    height: 18px;
    line-height: 19px;
  }
}

.procedure-modal {
  .content {
    .header {
      background-color: transparent;
      z-index: 0;
    }
  }
}
.custom-aside-menu .menu-nav .menu-item .menu-link {
  min-width: 201px;
  max-width: 201px;
}

.labor-date-picker {
  width: auto !important;
}
.custom-aside-menu {
  .menu-setting-2 {
    .accordion {
      .menu-item .menu-link {
        min-width: auto;
        max-width: auto;
      }
    }
  }
}
.aside-minimize:not(.aside-minimize-hover)
  .aside
  .menu-nav
  .menu-item
  .menu-link {
  min-width: auto !important;
}

.add-procedure-dropdown-n {
  p {
    white-space: nowrap;
  }
  .custom-menu-dropdown .dropdown {
    min-width: auto;
  }
  // .custom-menu-dropdown
  //   .dropdown
  //   .option-toggle.inspection-option-dropdown:hover {
  //   color: #fff !important;
  // }
}

.work-order-preview-tooltip {
  &.parts {
    line-height: 16px !important;
  }
}

.direction-rtl {
  .custom-menu-dropdown .dropdown.show .menu .item {
    padding: 10px 10px 10px 8px;
    font-size: 14px;
    cursor: pointer;
    &.divider {
      border-bottom: 1px solid #e4e6ea;
      padding: 0 !important;
    }
  }

  .action-custom-menu-dropdown .dropdown.show .menu .item {
    padding: 9px 7px 10px 8px !important;
    font-size: 14px;
    cursor: pointer;
    &.divider {
      border-bottom: 1px solid #e4e6ea;
      padding: 0 !important;
    }
  }
}

button {
  font-family: "Roboto" !important;
}

textarea {
  font-family: "Roboto" !important;
}
input {
  font-family: "Roboto" !important;
}

time {
  font-family: "Roboto" !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto" !important;
}

.field {
  .ui.dropdown .menu > .item > .image,
  .ui.dropdown .menu > .item > img,
  .ui.dropdown > .text > .image,
  .ui.dropdown > .text > img {
    width: 20px;
    height: 20px;
  }
}

.work-order-tooltip {
  color: white !important;
  margin: -2px 0px -1px 0px;
}

.grid-container-element {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  border: 1px solid transparent;
  width: 100%;
  background-color: #e9f2ff;
  border-radius: 5px 5px 0px 0px;

  &.transparent {
    background-color: transparent;
  }

  &.set {
    grid-template-columns: 1fr 0.5fr 0.5fr 0.1fr;
    grid-gap: 0px;
    border-radius: 0px;

    &.po-form {
      grid-template-columns: 0.5fr 0.4fr 0.4fr 0.4fr 0.4fr 0.1fr !important;
    }
    &.wo-form {
      // grid-template-columns: 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.1fr !important;

      grid-template-columns: 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr !important;
    }
    &.labor-form {
      // grid-template-columns: 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.1fr !important;

      grid-template-columns: 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.1fr !important;
    }
    &.cost-form {
      // grid-template-columns: 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.1fr !important;

      grid-template-columns: 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.1fr !important;
    }
    &.wo-form2 {
      grid-template-columns: 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.1fr !important;
    }
    &.wo-form-data {
      grid-template-columns: 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr !important;
    }
  }
  &.form {
    background-color: transparent;
    border-radius: 0px;
  }
}

.wo-table-div {
  overflow: auto !important;
  height: 488px !important  ;
  // style={{overflow:"auto",height:"480px"}}
}
.grid-child-element {
  padding: 8px 16px 8px 16px;
  &.set {
    padding: 6px 4px 4px 10px;

    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  &.form {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
  &.wo-data-form {
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    display: flex;
    align-items: center;
  }

  &.first {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  &.second {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #44546f;
  }
}

.wraper-drop-masure {
  .ui.dropdown .menu > .item {
    border-left: 2px solid transparent;
  }
  .ui.dropdown .menu > .item:hover {
    background-color: #f5f6f8;
    border-left: 2px solid #0c66e4;
  }
}

.react-time-picker__wrapper {
  border-radius: 3px !important;
  border: 2px solid rgba(9, 30, 66, 0.14) !important;
  background: #fff !important;
  line-height: 24px !important;
  font-family: "Roboto" !important;
  padding: 4px 8px !important;
  color: #44546f !important;
}

.react-time-picker__inputGroup__input:invalid {
  background: transparent !important;
}

.react-time-picker__inputGroup__hour {
  width: 20px !important;

  &:focus-visible {
    outline: none !important;
  }
}
.react-time-picker__inputGroup__minute {
  width: 23px !important;

  &:focus-visible {
    outline: none !important;
  }
}

.react-time-picker__inputGroup__leadingZero {
  // display: none !important;
  color: black !important;
}

.react-time-picker__inputGroup__divider {
  margin-right: 6px !important;
}

.ui.dropdown .menu > .item:hover {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
  z-index: 13;
}
.action-custom-menu-dropdown-2 {
  .dropdown.show .menu .item :hover {
    border-left: 0px solid transparent;
  }
  .dropdown.show .menu :hover {
    color: black;
    background-color: #f5f6f8;
    border-left: 2px solid #0c66e4;
  }
  .dropdown.show .menu .selected {
    color: black;
    background-color: #f5f6f8;
    border-left: 2px solid #0c66e4;
  }
}

.warrenty-file-dropzone {
  border-radius: 2px;
  border: 2px solid rgba(23, 43, 77, 0.14);
  background: rgba(233, 242, 255, 0.3);
  padding: 16px;

  &.qr-code-conrtainer {
    margin-top: 24px !important;
  }
  .p1 {
    color: #172b4d;
    /* Title Large 22\28 */
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 127.273% */
  }

  .p2 {
    color: #788ca5;
    /* Body Medium 14\20 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
  }
}

.set-input {
  .ui.input > input {
    border: 2px solid rgba(9, 30, 66, 0.14) !important;
    border-radius: 5px !important;
    height: 34px;
    font-size: 14px;
    font-weight: 400;
    color: #172b4d;
    padding-left: 35px !important;

    &::placeholder {
      color: #44546f !important;
      font-size: 14px;
    }

    &:focus {
      border: 2px solid blue !important ;
    }
  }
  span {
    position: absolute;
    z-index: 1;
    margin: 8px;
    overflow: visible !important;
  }

  .ui.icon.input > i.icon {
    display: none;
  }
}
.image-dropzone {
  border-radius: 3px;
  border: 2px dashed #e4e6ea;
  background: rgba(233, 242, 255, 0.1);
  padding: 22px;

  &.blue-border {
    border: 1px solid #0c66e4 !important;
    background: #e9f2ff;
  }

  &.file {
    border: 2px solid #e4e6ea;
    padding: 20px 16px 20px 0px;
    background-color: rgba(233, 242, 255, 0.3);
  }
  .p1 {
    color: #44546f;
    text-align: center;
    /* Body Large 16\24 */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
  }
  .p2 {
    color: #788ca5;
    /* Body Medium 14\20 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
}

.set-section-add {
  display: flex;
  align-items: baseline;
  padding-bottom: 4px;
  .ui.error.input {
    display: block;
  }
  .number-cost-set {
    display: inline-block;
    margin-bottom: 5px;
  }
  .ui.input > input {
    padding: 7px 8px !important;
    color: #44546f !important;
    border: 1px solid #788ca5 !important;
  }
}

.set-section-test {
  border: 1px solid #e4e6ea;
  background-color: #f5f6f8;
  border-radius: 3px !important;
  padding: 1px 0 1px 0;
  span {
    font-weight: 500;
    background-color: #e4e6ea;
    font-size: 16px;
    border-radius: 3px;
    padding: 2px;
    line-height: 23px !important;
    word-break: break-all;
  }
}

.custom-tooltip-assets {
  position: absolute;
  left: 23px;
  top: -15px;
  width: max-content;
  z-index: 2;
  // .wrapper-assets-tooltip {
  //   color: white;
  //   padding: 10px;
  //   background-color: rgba(23, 43, 77, 1);
  //   box-shadow: 0px 4px 4px 0px rgba(12, 102, 228, 0.1);
  //   border-radius: 5px;
  //   &::after {
  //     content: "";
  //     position: absolute;
  //     top: 10px;
  //     left: 0;
  //     margin-left: -22px;
  //     border-width: 12px;
  //     border-style: solid;
  //     border-color: rgba(23, 43, 77, 1) transparent transparent transparent;
  //     transform: rotate(91deg);
  //   }
  // }

  .wrapper-assets-tooltip {
    color: black;
    padding: 10px;
    font-weight: 400 !important;
    background-color: white !important;
    box-shadow: 0px 4px 4px 0px rgba(12, 102, 228, 0.1);
    border-radius: 5px;
    &::after {
      content: "";
      position: absolute;
      top: 10px;
      left: 0;
      margin-left: -22px;
      border-width: 12px;
      border-style: solid;
      border-color: white transparent transparent transparent;
      transform: rotate(91deg);
    }
  }
}
.custom-2-assets-dropdown {
  .dropdown .menu .custom-dropdown-item {
    min-height: 32px !important;
    padding: 8px;
    border-bottom: 1px solid rgba(228, 230, 234, 1);
    // margin: 0 8px;
    border-left: 2px solid transparent;
    &:hover {
      background-color: #f5f6f8 !important;
      background-color: transparent !important;
      border-left: 2px solid #0c66e4;
      border-bottom: 2px solid rgba(228, 230, 234, 1) !important;
    }
  }
  .dropdown .menu .custom-dropdown-item.selected {
    background-color: rgba(228, 230, 234, 0.5) !important;
    border-left: 2px solid #0c66e4;
  }
  .dropdown .menu .custom-dropdown-item .custom-dropdown-text {
    color: rgba(23, 43, 77, 1);
    font-size: 14px;
    font-weight: 400;
  }
  .dropdown.dropdown.active .menu .item.custom-checkbox.selected {
    border-left: 2px solid #0c66e4;
  }
}

.without_ampm input[type="time"]::-webkit-datetime-edit-ampm-field {
  display: none !important;
}

.new-grey-btn {
  background-color: #e4e6ea;
  color: #172b4d;
  font-weight: 500;
  font-size: 16px;
  border-radius: 3px;
  line-height: 24px;
  padding: 3px 15px 2px;
  margin-right: 25px;
  margin-left: 25px;

  &.green {
    background-color: #4fab681a;
  }
  &.red {
    background-color: #d140401a;
  }

  &.restock {
    padding: 3px 5px 2px;
  }
}
.labor-grey-btn {
  background-color: #f5f6f8;
  color: #172b4d;
  display: flex;
  align-items: center;
  font-weight: 500;
  border: 1px solid #e4e6ea;
  font-size: 16px;
  border-radius: 3px;
  line-height: 24px;
  padding: 4px 8px 6px 8px;
}

.part-list-modal {
  .ui.checkbox input:checked ~ label:after {
    opacity: 1;
    color: #fff !important;
    background-color: #0c66e4;
  }

  .ui.checkbox label:after {
    // width: 12px !important;
    // height: 12px !important;
    border-radius: 3px;
  }

  // .ui.checkbox label:before {
  //   width: 12px !important;
  //   height: 12px !important;
  //   border-radius: 3px;
  // }
}
.new-purchase-order-modal {
  .ui.checkbox input:checked ~ label:after {
    opacity: 1;
    color: #fff !important;
    background-color: #0c66e4;
  }

  .ui.disabled.fitted.checkbox input:disabled + label:before {
    background: lightgray !important;
  }
  .ui.checkbox label:after {
    border-radius: 3px;
  }
}

.part-list-modal2 {
  .ui.checkbox input:checked ~ label:after {
    opacity: 1;
    color: #fff !important;
    background-color: #0c66e4;
  }

  .ui.disabled.fitted.checkbox input:disabled + label:before {
    background: lightgray !important;
  }
  .ui.checkbox label:after {
    border-radius: 3px;
  }
}

// .add-button {
//   position: relative;
//   width: 150px;
//   height: 40px;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   border: 1px solid #34974d;
//   background-color: #3aa856;

//   &.svg {
//     width: 30px;
//     stroke: #fff;
//   }

//   .add-button-text {
//     transform: translateX(30px);
//     color: #fff;
//     font-weight: 600;
//     transition: all 0.3s;

//     &:hover {
//       color: transparent;
//     }
//   }

//   .add-button-img {
//     position: absolute;
//     transform: translateX(109px);
//     height: 100%;
//     width: 39px;
//     background-color: #34974d;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     transition: all 0.3s;

//     &:active {
//       background-color: #2e8644;
//     }

//     &:hover {
//       width: 148px;
//       transform: translateX(0);
//     }
//   }

//   &:active {
//     border: 1px solid #2e8644;
//   }

//   &:hover {
//     background: #34974d;
//   }
// }

.add-button {
  position: relative;
  width: 160px;
  height: 40px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 0px;
  border-radius: 3px;
  background-color: #0c66e4;
  // border: 1px solid #0C66E4;

  &,
  &-img,
  &-text {
    transition: all 0.3s;
  }

  &-text {
    transform: translateX(15px);
    color: #fff;
    font-weight: 500;
  }

  &-img {
    position: absolute;
    transform: translateX(115px);
    height: 100%;
    width: 38px;
    background-color: #0a55bd;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 30px;
    stroke: #fff;
  }

  &:hover {
    background: #0a55bd;

    .add-button-text {
      color: transparent;
    }

    .add-button-img {
      width: 148px;
      transform: translateX(0);
    }
  }
}

.custom-tooltip {
  position: absolute;
  top: 58%;
  left: 66%;
  transform: translateX(-50%);
  visibility: visible;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  z-index: 1000;

  .tooltip-content {
    position: relative;
    background: white;
    border-radius: 8px;
    border: 1px solid #f5f6f8;
    padding: 16px;
    width: 250px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    margin-top: 8px;

    // Arrow

    h3 {
      font-size: 1.125rem;
      font-weight: 600;
      text-align: start;
      margin-bottom: 1rem;
      color: #1a1a1a;
    }

    .cost-breakdown {
      display: flex;
      flex-direction: column;

      .cost-row {
        display: flex;
        justify-content: start;
        align-items: start;
        text-align: start;
        margin-top: 0px;

        .cost-label {
          color: #44546f;
          width: 125px;
        }

        .cost-value {
          font-weight: 500;
        }
      }
    }
  }
}

.part-list-modal {
  .ui.checkbox input:checked ~ label:after {
    opacity: 1;
    color: #fff !important;
    background-color: #0c66e4;
  }

  .ui.disabled.fitted.checkbox input:disabled + label:before {
    background: lightgray !important;
  }
  .ui.checkbox label:after {
    border-radius: 3px;
  }
}
.width-content {
  display: inline-block;
}
.set-position-of-percentage {
  margin: 5px 28px 5px 0;
  .fix-width-label {
    width: 95px !important;
    display: inline-block;
    opacity: 0.8;
    text-align: left;
  }
  .span-number-per {
    display: block;
    color: #788ca5;
    padding-right: 48px;
  }
}
.change-radio-icon-color {
  .ui.radio.checkbox input:checked ~ label:after {
    background-color: #0c66e4 !important;
  }
}
.new-button-add-parts {
  .form-action-button {
    font-size: 16px;
    font-weight: 500;
    padding: 4px 16px;
    line-height: 30px;
    border: 1px solid #0c66e4;
    &.light-blue-col {
      background-color: #e9f2ff !important;
      color: #0c66e4 !important;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.billing-info2 {
  display: flex;
  align-items: end;
}

.order-items-section {
  .grid-container-element.set2 {
    grid-template-columns: 0.8fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
    grid-gap: 0px;
    border-radius: 0px;
  }
  .grid-child-element {
    padding: 5px;
    font-weight: 500;
  }
  .blue-item {
    color: #0c66e4 !important;
    text-decoration: underline;
    word-break: break-all;
  }
  .detail-order-section {
    .grid-container-element.set2 {
      background-color: #fff;
      .grid-child-element {
        font-size: 14px;
        font-weight: 400;
        color: #172b4d;
      }
    }
  }
}

.detail-of-sub-section-order {
  padding-left: 50%;
  width: 100%;
  .sub-header-oredr-section {
    width: 100%;
  }
  .tri-grid-section-or {
    grid-template-columns: 2fr 1fr 1fr;
    display: grid;
    grid-gap: 5px;
  }
  .title-sub-order {
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 0px;
    }
  }
  .detail-sub-order {
    font-size: 14px;
    font-weight: 400;
    color: #44546f;
  }
}

.change-for-job-btn {
  .new-grey-btn {
    padding: 3px 5px 2px;
    margin-right: 28px;
  }
}
.set-section-add .number-cost-set {
  min-width: 50px;
}

.po-section-wrapper {
  .po-section-detail {
    background-color: #f5f6f8;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  .po-section-header {
    background-color: #e9f2ff;
    border-radius: 5px 5px 0px 0px;
    padding: 8px 16px 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p:first-child {
      color: #0074d9;
      font-size: 22px;
      margin: 0;
    }
    p:last-child {
      color: #44546f;
      font-size: 14px;
      margin: 0;
    }
  }
  .po-section-detail-in {
    border: 1px solid #e4e6ea;
    border-radius: 0 0 5px 5px;
    padding: 16px;
    .unit-text-po {
      color: #788ca5;
      font-size: 14px;
      span {
        color: #172b4d;
        font-size: 16px 16px 0 16px;
      }
    }
  }
}

.order-item-header-change {
  .grid-container-element.set2 {
    display: flex;
    // overflow-x: auto;
  }
  .grid-child-element {
    padding: 5px;
    font-weight: 500;
    width: -webkit-fill-available;
  }
}
.wrapper-new-comment-container {
  width: -webkit-fill-available;
  .kanbar-header {
    width: fit-content;
  }
  .over-flow-scroll-table {
    overflow-x: auto;
  }
  .grid-container-element.set {
    display: flex;
    overflow-x: auto;

    .grid-child-element.set {
      width: -webkit-fill-available;
      white-space: nowrap;
    }
  }
  .grey-btn-number {
    padding: 0px 12px 0 14px;
  }
  .set-position-of-percentage {
    .ui.input > input {
      text-align: center !important;
    }
  }
}

.ui.light-purple.label {
  background-color: #554feb !important;
}

// new scroll setting for modal
body {
  overflow-y: hidden;
  .content-heignt-min,
  #kt_content {
    overflow-y: auto;
    height: 100vh;
    &::-webkit-scrollbar {
      width: 5px !important;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #788ca5;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.scroll-width {
  overflow-y: auto;
  height: 100vh;
  &::-webkit-scrollbar {
    width: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #788ca5;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.workorder-intro-value.parts2 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.workorder-intro-value.parts3 {
  margin-left: 17px !important;
  margin-right: 17px !important;
  margin-top: 10px !important;
}

.inventory-detail-wrap {
  .grid-container-element {
    display: grid;
    grid-template-columns: 58% 37%;
  }
}
.inventory-details {
  .ui.grid {
    display: grid;
    grid-template-columns: 59% 37%;
    .column {
      width: 100% !important;
    }
  }
}

.assets-modal {
  .modal-n-fufill-btn.modal-button-container {
    padding: 0 20px;
    .modal-form-action-button.light-blue {
      width: -webkit-fill-available !important;
    }
  }
}
.set-section-add .number-cost-set {
  min-width: 55px !important;
}

.change-date-time-inll {
  .rmdp-container {
    display: block !important;
  }
}

.hide-on-non-focused {
  .ui.segment {
    padding: 8px 16px 8px 16px !important;
    border-radius: 8px;
  }

  .logic-box-for-isc {
    border: 1px solid #788ca5;
    border-radius: 4px;
    padding: 9px 15px 15px 15px;

    .procedures-box {
      .red-box {
        background-color: "#fff6f6";
        border-color: "#D14040";
        color: "#D14040";
      }
    }
  }
}

.sub-text {
  color: #172b4d !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.accordion-item {
  .header {
    z-index: auto !important;
  }
}

.WO-link {
  padding: 15px;
  background: #4fab681a;
  border: 1px solid #4fab68;
  border-radius: 4px;
}

.progress-container {
  display: flex;
  align-items: center;
  width: 100%; /* Full width */
  margin-bottom: 2rem; /* Adjust margin as needed */
}

.progress-bar {
  flex-grow: 1 !important; /* Allow the bar to grow and fill available space */
}

.progress-number {
  margin-left: 1rem !important; /* Adjust margin as needed */
  white-space: nowrap !important; /* Prevent number from wrapping */
}

.step-image {
  // width: 48px;
  // height: 48px;
  transition: all 0.001s ease;
}
.set-checkbox-gery {
  .ui.disabled.fitted.checkbox input:disabled + label:before {
    background: lightgray !important;
  }
  .ui.disabled.fitted.checkbox input:disabled {
    background: lightgray !important;
  }
}

.link-span {
  svg {
    margin-top: -1px;
  }
}

// .rdt_TableCol{
//   &:first-child, &:nth-child(2){
//     position: sticky;
//     left: 0;
//     z-index: 9;
//     background-color:red;
//   }

//   &:nth-child(2){
//     left: 113px;
//   }
// }

// .rdt_TableCell{
//   &:first-child, &:nth-child(2){
//     position: sticky;
//     left: 0;
//     z-index: 9;
//     background-color:red;
//   }

//   &:nth-child(2){
//     left: 113px;
//   }
// }

.terms-conditions {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #788ca5;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.apexcharts-tooltip.apexcharts-theme-dark {
  background: transparent !important;
}

.resolution-time-table {
  border-collapse: collapse;
  width: 100%;
}

.table-container {
  border: none;
  overflow: auto;
  // overflow-x: hidden;
  padding-inline: 17px;
  height: 326px;

  &.h-500 {
    height: 400px;
  }

  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #788ca5;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

table th,
table td {
  border: none;
  padding: 3px;
  text-align: left;
  font-size: 14px;
  color: rgba(23, 43, 77, 1);
}

.table-container table th {
  color: #44546f !important;
  font-weight: 500;
  font-size: 14px;
}

.table-container table td:nth-child(2) {
  color: rgba(120, 140, 165, 1);
}

.table-container table td:nth-child(3) {
  color: rgba(209, 64, 64, 1);
}
.table-container table td:nth-child(4) {
  color: rgba(226, 178, 3, 1);
}
.table-container table td:nth-child(5) {
  color: rgba(79, 171, 104, 1);
}

.table-container {
  &.utilisation {
    height: 143px !important;

    &.h-500 {
      height: 300px !important;
    }
  }

  &.top-ten {
    height: 328px !important;
    &.h-500 {
      height: 317px !important;
    }
    table th,
    td {
      padding: 4.5px !important;
    }
    table td {
      color: #788ca5 !important;
    }
    table td:nth-child(1) {
      color: #172b4d !important;
    }

    table td:nth-child(2) {
      color: #172b4d !important;
      font-weight: 400;
      > div {
        width: 54px;
        text-align: center;
      }
    }
    table td:nth-child(3) {
      color: #0c66e4 !important;
      font-weight: 400;
      > div {
        width: 54px;
        text-align: center;
      }
    }
    table td:nth-child(4) {
      color: #4fab68 !important;
      font-weight: 400;
      > div {
        width: 58px;
        text-align: center;
      }
    }
    table td:nth-child(5) {
      color: #d14040 !important;
      font-weight: 400;
      > div {
        width: 50px;
        text-align: center;
      }
    }
  }

  &.asset-schedual {
    height: 192px !important;

    .pill {
      border-radius: 10px;
      border: 1px solid #e9f2ff;
      background: #e9f2ff;
      color: #0c66e4;
      padding: 3px 5px;
      min-width: 87px;
      text-align: start;
      // font-family: Roboto;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      // padding: 3px 8px;
      &.yellow {
        background-color: #fbbd08;
        color: #fff;
      }
      &.orange {
        background-color: orange;
        color: #fff;
      }
      &.green {
        background-color: green;
        color: #fff;
      }
      &.active-green {
        background-color: rgba(79, 171, 104, 0.1);
        color: #4fab68;
        border: 1px solid #4fab68;
      }

      &.pending-yellow {
        background-color: rgba(226, 178, 3, 0.1);
        color: #e2b203;
        border: 1px solid #e2b203;
      }
      &.inactive-grey {
        background-color: #f5f6f8;
        color: #788ca5;
        border: 1px solid #788ca5;
      }
      &.high {
        background-color: #d14040;
        color: #ffffff;
      }
      &.red {
        background-color: #d14040;
        color: #ffffff;
      }
      &.high-low {
        background-color: rgba(209, 64, 64, 0.1);
        color: rgba(209, 64, 64, 1);
        border: 1px solid rgba(209, 64, 64, 1);
      }
      &.medium {
        background-color: #e2b203;
        color: #ffffff;
      }
      &.low {
        background-color: #4fab68;
        color: #ffffff;
      }
      &.grey {
        color: #44546f !important;
        /* Body Medium 14\20 */
        font-family: "Roboto";
        font-size: 14px !important;

        line-height: 20px !important; /* 142.857% */
        letter-spacing: 0.25px;
        background-color: #e4e6ea;
      }
      &.none {
        background-color: #788ca5;
        color: #e4e6ea !important;
      }
    }
    &.h-500 {
      height: 310px !important;
    }
    table th,
    td {
      padding: 4.5px !important;
    }
    table td {
      color: #788ca5 !important;
    }

    // table td:nth-child(1) {

    //   display: flex;
    //   align-items: center;
    //   gap: 5px;
    // }
    table td:nth-child(2) {
      color: #0c66e4 !important;
      font-weight: 400;
      text-decoration: underline;
      cursor: pointer;
      // > div{
      //   width: 54px;
      //   text-align: center;
      // }
    }
    table td:nth-child(3) {
      color: #44546f !important;
      font-weight: 400;
      // > div{
      //   width: 58px;
      //   text-align: center;
      // }
    }
    table td:nth-child(4) {
      // color: #d14040 !important;
      // font-weight: 400;
      // > div{
      //   width: 50px;
      //   text-align: center;
      // }
    }
  }
}

.scroll-data {
  &::-webkit-scrollbar {
    height: 5px !important;
    width: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #788ca5;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.label-text-util {
  font-size: 16px;
  color: rgba(138, 146, 166, 1);
  p {
    color: rgba(35, 45, 66, 1);
  }
}

.average-time {
  display: flex;
  flex-direction: column;
}
.header-textarea {
  padding: 6px 8px;
}

.segament {
  border-color: #0c66e4 !important;
  min-height: 60px;
  padding-inline: 10px !important;
}

.procedure-container .main-div.custom-textarea-parent {
  margin-bottom: 30px !important;
  height: auto;
}
.custom-text-area {
  margin: 0;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  padding: 0.78571429em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: 0;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color 0.1s ease, border-color 0.1s ease;
  font-size: 1em;
  line-height: 1.2857;
  resize: vertical;
  width: 100%;
  min-height: 60px;

  &:focus {
    border: 2px solid #0c66e4 !important;
  }
}
// .asset-modal-content{
.bulk-upload-table {
  .text-dark-red {
    color: #d14040;
    font-weight: 500;
  }
  .text-dark-yellow {
    color: #e2b203;
    font-weight: 500;
  }
  .text-dark-green {
    color: #4fab68;
    font-weight: 500;
  }
  .rdt_TableHead {
    .rdt_TableHeadRow {
      color: #172b4d;
      /* Title Small 14\20 */

      background-color: #e9f2ff !important;
    }
  }
}
// }
.after-map-div {
  // position: absolute;
  // top: 162px;
  // left: 171px;
  // right: 0px;
  // .rmdp-wrapper {
  //   width: 440px !important;
  // }

  /* The above code appears to be a commented-out section of SCSS code. It defines a CSS class `.b-date`
with the following properties:
- `background-color: white !important;`
- `border: none !important;`
- `font-size: 14px;`
- `padding: 4px;` */

  // .rmdp-panel-body {
  //   button {
  //     border: none !important;
  //     color: white !important;
  //     font-size: 14px;
  //     padding: 4px;

  //     &:hover {
  //       color: white !important;
  //     }
  //   }
  // }

  .rmdp-arrow-container {
    background: transparent !important;
    border: none !important;
    border-radius: 50% !important;
    cursor: pointer;
    display: flex;
    height: 20px;
    justify-content: center;
    margin: 0 5px;
    padding: 0;
    width: 20px;

    &:hover {
      .rmdp-arrow {
        border: solid #0074d9;
        border-width: 0 2px 2px 0;
      }

      border: none !important;
      color: #44546f !important;
      background-color: transparent !important;
      box-shadow: none !important;
    }
  }
}

.dashboard-refresh-btn {
  height: 100%;
  width: 100%;
  font-weight: 500;
  background-color: #e9f2ff;
  border-radius: 3px;
  font-size: 14px;
  padding: 5px 7px;
  border: 1px solid;
  border-color: #0c66e4;
}

.circular-label {
  display: inline-block;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.dashboard-dropdown {
  &.ui.selection.dropdown {
    min-width: 96px !important;
  }
}

.filter-count-div {
  margin-top: -17px;
  padding: 2px 3px 2px 3px;
  /* padding: 3px 8px 3px 8px; */
  background: #0c66e4;
  border: 2px;
  border-radius: 6px;
  color: white;
  margin-inline: 4px;
  line-height: 25px;
}

.sub-location-dropdown-option {
  margin-left: 15px !important; /* Adjust as needed */

  img {
    height: auto !important;
    width: auto !important;
  }

  // &:hover{
  //   margin-left: 0px !important;
  // }
}
.grandchild-asset-dropdown-option {
  margin-left: 30px !important; /* Adjust as needed */

  img {
    height: auto !important;
    width: auto !important;
  }

  // &:hover{
  //   margin-left: 0px !important;
  // }
}
.main-location-dropdown-option {
  img {
    height: auto !important;
    width: auto !important;
  }

  // &:hover{
  //   margin-left: 0px !important;
  // }
}

.gm-style-iw-chr {
  display: none !important;
}

.dashboard-tabs {
  .ui.attached.tabular.menu {
    display: none;
    position: sticky !important;
    top: 44px !important;
    z-index: 50 !important;
  }
}
.table-header-dashbaord {
  .dashboard-filters {
    gap: 20px;
    display: flex;
    justify-content: space-between;
    align-items: start;

    .pill {
      border-radius: 10px;
      border: 1px solid #e9f2ff;
      background: #e9f2ff;
      color: #0c66e4;
      padding: 3px 5px;
      min-width: 87px;
      text-align: start;
      // font-family: Roboto;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      // padding: 3px 8px;
      &.yellow {
        background-color: #fbbd08;
        color: #fff;
      }
      &.orange {
        background-color: orange;
        color: #fff;
      }
      &.green {
        background-color: green;
        color: #fff;
      }
      &.active-green {
        background-color: rgba(79, 171, 104, 0.1);
        color: #4fab68;
        border: 1px solid #4fab68;
      }

      &.pending-yellow {
        background-color: rgba(226, 178, 3, 0.1);
        color: #e2b203;
        border: 1px solid #e2b203;
      }
      &.inactive-grey {
        background-color: #f5f6f8;
        color: #788ca5;
        border: 1px solid #788ca5;
      }
      &.high {
        background-color: #d14040;
        color: #ffffff;
      }
      &.red {
        background-color: #d14040;
        color: #ffffff;
      }
      &.high-low {
        background-color: rgba(209, 64, 64, 0.1);
        color: rgba(209, 64, 64, 1);
        border: 1px solid rgba(209, 64, 64, 1);
      }
      &.medium {
        background-color: #e2b203;
        color: #ffffff;
      }
      &.low {
        background-color: #4fab68;
        color: #ffffff;
      }
      &.grey {
        color: #44546f !important;
        /* Body Medium 14\20 */
        font-family: "Roboto";
        font-size: 14px !important;

        line-height: 20px !important; /* 142.857% */
        letter-spacing: 0.25px;
        background-color: #e4e6ea;
      }
      &.none {
        background-color: #788ca5;
        color: #e4e6ea !important;
      }
    }

    .table-searchbar {
      position: relative !important;

      .ui.input {
        width: -webkit-fill-available !important;
      }
    }

    .table-searchbar > svg {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 18px;
      z-index: 1;
    }

    .ui.icon.input > i.icon {
      display: none;
    }
  }
  h2.ui.header {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    background: transparent;
    font-size: 24px;
    font-weight: 400;
    justify-content: flex-start;
    margin-bottom: 0;
    color: #172b4d;
    font-style: normal;
    line-height: 32px;
  }
  .header-actions {
    display: flex;

    align-items: center;
    justify-content: space-between;

    // .add-item-btn{
    //   border: 2px solid #24b4fb;
    //   background-color: #24b4fb;
    //   border-radius: 0.9em;
    //   cursor: pointer;
    //   padding: 0.8em 1.2em 0.8em 1em;
    //   transition: all ease-in-out 0.2s;
    //   font-size: 16px;

    //   :hover {
    //     background-color: #0071e2;
    //   }

    // }
  }

  .buttons.basic.add-people-btn .button.active {
    color: #172b4d !important;
    border-bottom: 1px solid #000 !important;
  }
  &.team-filter {
    .ui.basic.buttons .active.button {
      background: transparent !important;
    }
  }

  .ui.basic.buttons .active.button {
    background: #f9fcff !important;
  }
  .ui.basic.buttons .button:hover {
    background: transparent !important;
  }

  .buttons.basic.add-people-btn .button {
    border: none !important;
    border-radius: 0 !important;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    font-family: Roboto;
    font-weight: 500;
    color: #788ca5;
    background-color: transparent !important;
    padding: 0;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0; /* Position on the right */
  height: auto;
  min-height: 897px;
  background-color: white;
  border-left: 1px solid #ddd; /* Border on the left side of the sidebar */
  overflow: hidden;
}

// .pane-content {
//   margin-right: 260px; /* Adjust according to the sidebar width */
//   padding: 16px;
//   overflow-y: auto;
//   height: 100vh;
// }

.people-dropdown {
  .ui.label > img {
    width: auto !important;
    vertical-align: middle;
    height: auto !important;
    margin-right: 5px;
    background-color: #e8e8e8 !important;
  }
  .ui {
    display: inline-flex !important;
  }

  .ui.label > .delete.icon {
    margin-top: -4px !important;
  }
}

.loc-dropdown {
  img {
    width: auto !important;
    height: auto !important;
    margin-right: 0.78571429rem !important;
  }
}

// .ui .fluid .multiple .search .selection .dropdown {
// .ui.label>img {
//   background: transparent;
//   width: 25px !important;
//   vertical-align: middle;
//   height: auto !important;
//   margin-right: 7px;
// }

// .img.ui.image {
//   display: inline-block !important;
// }
// }

// .side-item-menu{
//   .items-menu{
//     padding-left: 0px !important;
//     padding-right: 0px !important;
//   }
// }

.side-item-menu {
  padding: 0px !important;
}

.new-listing-container {
  &.table-header-dashboard {
    padding: 22px 16px;
    position: sticky;
    z-index: 50;
    top: 0px;
    background: #f9fcff;
  }

  .header-actions {
    position: sticky;
    z-index: 50;
    top: 0px;
    margin-bottom: 11px;
  }
}
.procedure-container {
  &.table-header-dashboard {
    padding: 22px 16px;
    position: sticky;
    z-index: 50;
    top: 0px;
    background: #f9fcff;
  }

  .header-actions {
    position: sticky;
    z-index: 50;
    top: 0px;
    margin-bottom: 11px;
  }
}

.ui.form textarea:focus {
  border: 2px solid #0c66e4 !important;
}

.ui.input > input:focus {
  border: 2px solid #0c66e4 !important;
}

.ui.input > input:focus {
}

.ui.selection.active.dropdown .menu {
  border: 2px solid #0c66e4 !important;
}

.ui.active.selection.dropdown {
  border: 2px solid #0c66e4 !important;
}

.ui.selection.dropdown:focus {
  border: 2px solid #0c66e4 !important;
  // border-bottom: 0px !important;
}

.ui.input.error > input {
  ::placeholder {
    color: #9f3a38 !important;
  }
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
  box-shadow: none;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
  100% {
    transform: scale(0.8);
    opacity: 1;
  }
}

// .metrics-card-container {
//   max-width: 64rem; // 1024px
//   margin: 0 auto;
//   padding: 1.5rem;
// }

.metrics-card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb;
  padding: 1.5rem 0px;
}

.metrics-grid {
  display: grid;
  grid-template-columns: 1fr;
  // gap: 1rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.metrics-item {
  padding: 1rem 10px;
  border-bottom: 1px solid #d1d5db;
  text-align: center;

  @media (min-width: 768px) {
    // padding: 6px;
    //     border-bottom: none;
    //     background: #E9F2FF80;
    //     margin-inline: 10px;
    //     border-radius: 8px;
    padding: 0 10px;
    border-bottom: none;
    border-right: 1px solid #d1d5db;

    &:last-child {
      border-right: none;
    }

    &:not(:first-child) {
      // padding-left: 1rem;
    }
  }

  h2 {
    color: #44546f;
    margin-top: 10px !important;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .value {
    color: #0c66e4;
    font-size: 20px;
    font-weight: 600;

    &.black {
      color: #44546f;
    }
    // margin-right: px;
  }

  .sub-value {
    color: #788ca5;
    font-size: 12px;
    margin-top: 7px;
  }

  // &.no-hover {
  //   &:hover {
  //     margin: 0 !important;
  //     border-radius: 0px !important;
  //     background-color: transparent !important; /* Light Blue */
  //     box-shadow: none !important; /* Subtle shadow */
  //     // cursor: pointer;
  //   }
  // }

  &:hover {
    margin: 0px 10px;
    border-radius: 8px;
    background-color: #e9f2ff80 !important; /* Light Blue */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    border-right: 0px; /* Border on hover */

    // cursor: pointer;
  }

  &:hover + .metrics-item {
    border-left: 1px solid #d1d5db; /* Blue border for next item */
  }
}

.status-container {
  position: relative;
  display: inline-block;
  .status-button {
    display: flex;
    align-items: center;
    background-color: #0066ff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    width: 154px;
    justify-content: space-between;
    height: 33px;
    font-weight: 400;

    &.open {
      background-color: #0066ff;
    }
    &.reOpen {
      background-color: #554feb;
    }
    &.inProgress {
      background-color: #e2b203;
    }
    &.onHold {
      background-color: #d14040;
    }
    &.inReview {
      background-color: #4fab68;
    }
    &.completed {
      background-color: #000563;
    }
    &.cancelled {
      background-color: #788ca5;
    }

    .status-text {
      flex: 1;
      font-size: 16px;
      padding: 8px 10px 8px 10px;
      font-weight: 500;
      text-align: center;
      text-transform: uppercase;
      // border-right: 1px solid white;
    }

    .status-arrow {
      padding: 4px;
      cursor: pointer;
    }
  }

  .status-dropdown-wo {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 5px;
    // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    width: 154px;
    margin-top: 5px;

    .status-item {
      padding: 8px 10px 8px 10px;
      text-align: center;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      border-radius: 5px;
      &.open {
        background-color: #0c66e426;
        color: #0c66e4;

        &:hover {
          background-color: #0c66e466; /* Darker shade on hover */
        }
      }

      &.reOpen {
        background-color: #554feb26;
        color: #554feb;

        &:hover {
          background-color: #554feb66; /* Darker shade on hover */
        }
      }
      /* In-Progress Status */
      &.in-progress {
        background-color: #e2b20326;
        color: #e2b203;

        &:hover {
          background-color: #e2b20366; /* Darker shade on hover */
        }
      }

      /* On-Hold Status */
      &.on-hold {
        background-color: #d1404026;
        color: #d14040;

        &:hover {
          background-color: #d1404066; /* Darker shade on hover */
        }
      }

      /* Completed Status */
      &.completed {
        background-color: #4fab6826;
        color: #4fab68;

        &:hover {
          background-color: #4fab6866; /* Darker shade on hover */
        }
      }

      /* Closed Status */
      &.closed {
        background-color: #2243b026;
        color: #172b4d;

        &:hover {
          background-color: #2243b066; /* Darker shade on hover */
        }
      }

      /* Canceled Status */
      &.canceled {
        background-color: #2243b026;
        color: #44546f;

        &:hover {
          background-color: #788ca566; /* Darker shade on hover */
        }
      }
    }
  }
}
.react-time-picker__wrapper {
  width: 252px;
}

.react-time-picker__clock {
  display: none;
}

.priority-label {
  padding: 6px 10px;
  border-radius: 15px;
  color: white !important;
  font-weight: 500;

  &.low {
    background-color: #28a745;

    /* Green */
  }

  &.medium {
    background-color: #ffc107; /* Yellow */
  }

  &.high {
    background-color: #dc3545; /* Red */
  }
}

.hover-container {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.close-icon {
  margin-left: 1px;
  margin-top: -6px;
  font-size: 18px;
  color: #888;
  cursor: pointer;
  display: none; /* Initially hidden */
  transition: color 0.2s ease;
}

.hover-container:hover .close-icon {
  display: inline;
}

.close-icon:hover {
  color: #e74c3c; /* Red on hover */
}

.priority-container {
  display: flex;
  flex-direction: column;
  gap: 2px;

  .priority-options {
    display: flex;
    gap: 12px;

    .priority-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 5px 14px;
      border: 2px solid #e4e6ea; // Direct border color
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s ease;
      font-size: 14px;
      font-weight: 500;
      color: #333;
      min-width: 125px;
      background-color: #F5F6F8;

      &:hover {
        background-color: #f3f4f6; // Direct hover background color
      }

      &.selected-low {
        border-color: #4fab68; // Direct selected border color
        background-color: #4fab681a; // Direct selected background color
        color: #4fab68;
      }

      &.selected-medium {
        border-color: #e2b203; // Direct selected border color
        background-color: #e2b2031a; // Direct selected background color
        color: #e2b203;
      }

      &.selected-high {
        border-color: #D14040; // Direct selected border color
        background-color: #D140401A; // Direct selected background color
        color: #d14040;
      }
    }
  }
}

.__react_component_tooltip {
  z-index: 1050 !important;  // Ensure it's above other elements
  // transform: translateY(-8px);  // Lift tooltip slightly if hidden
}

/* Button Styles */

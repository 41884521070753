.preview-details-wrapper {
  font-size: 14px;
  .intro {
  }

  .username-container {
    margin-left: 10px;

    .people-text {
      margin: 0px !important;
      color: #172b4d;
      text-align: start;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0.15px;
    }

    .people-role {
      margin: 0px !important;
      // margin-right: 8px !important;
      color: #788ca5;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 142.857% */
      letter-spacing: 0.15px;
    }
  }
  .details-list {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 20px;

    .list-item {
      .item-label {
        font-weight: 600;
        display: block;

        &.procedure-label{
          font-weight: 600;
          padding: 3px 5px 2px;
          display: inline-block;
          margin-right: 28px;
          background-color: #E4E6EA;
          color: #172B4D;
          font-weight: 500;
          font-size: 16px;
          border-radius: 3px;
          line-height: 24px;
          padding: 3px 9px 2px;

        }
      }
      .item-value {
        .procedure-item-action {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
          letter-spacing: 0.15px;
        }
      }
    }

    // .ui {
    //   &.divider {
    //     margin: 0;
    //   }
    // }
  }

  .segment {
    .details-list {
      margin-top: 0;
    }
  }
}

.ui {
  &.tabular {
    &.menu {
      .item {
        font-size: 16px;
      }
    }
  }
}

.custom-dropdown {
  .selected.item {
    background-color: #e2f5fc !important;
  }
}

.dynamic-modal,
.modal {
  .body,
  .content {
    .segment {
      .hide-on-non-focused {
        display: none;
      }
      &.active-field {
        box-shadow: 0 0 5px #2cbff6 !important;

        .hide-on-non-focused {
          display: block;
        }
      }
    }
  }
}

.procedure-actions {
  > div {
    &:first-child {
      // border-right: 1px solid #c7c7c7;
      padding-right: 5px;
      margin-right: 5px;

      // .react-toggle--checked .react-toggle-thumb {
      //   left: 23px !important;
      //   border-color: #19ab27;
      // }

      .required-text {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        font-weight: 400;
        color: #172b4d;
      }
    }
    &.rtl-container {
      padding-left: 5px !important;
      margin-right: 5px !important;
    }
  }

  .ui.checkbox {
    label {
      margin-bottom: 0;
    }
  }
}

.required-text {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  font-weight: 400;
  color: #172b4d;
}

.toggle-data {
  .react-toggle--checked .react-toggle-thumb {
    left: 23px !important;
    border-color: #19ab27;
  }
  .react-toggle-track {
    width: 44px;
    height: 18px;

    .react-toggle-track-x {
      width: 10px;
      height: 12px;
      top: 2px;
      bottom: 0px;
      right: 6px;
    }

    .react-toggle-track-check {
      left: 4px;
    }
  }
  .react-toggle-thumb {
    top: 1px;
    width: 16px;
    height: 16px;
  }
}

.preview-attachment-wrapper {
  width: 120px;
  display: inline-flex;
  margin-right: 5px;
  border: 1px solid #e0e5e9;
  position: relative;
  height: 120px;
  overflow: hidden;
  border-radius: 6px;
  padding: 10px;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  i {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    padding: 10px;
  }
}

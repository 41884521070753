//
// Login 1
// Pages SASS files are compiled into separate css files
//

// Initialization of global variables, mixins and functions
@import '../../../init';

.login.login-1 {
  .login-form {
    form {
      .form-group {
        margin-bottom: 0px;
        .form-label {
          color: rgba(68, 84, 111, 1);
          font-size: 16px;
          letter-spacing: 0.5px;
          font-weight: 400;
          line-height: 24px;
          font-family: 'Roboto';
          margin-bottom: 0px;
        }

        .form-control {
          display: block;
          width: 100%;
          height: calc(1.5em + 1.3rem + 2px);
          padding: 0.65rem 1rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          padding: 6px;
          color: #3f4254;
          background-color: #ffffff;
          background-clip: padding-box;
          border: 2px solid #e4e6ef;
          border-radius: 3px;
          box-shadow: none;
          height: 44px !important;
          transition: border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
        }

        .form-control::placeholder {
          /* Your placeholder styling goes here */
          color: rgba(98, 111, 134, 1);
          font-size: 14px; /* Placeholder text color */
          letter-spacing: 0.25px;
          font-weight: 400;
          line-height: 20px;
          font-family: 'Roboto';
        }
      }
      h3 {
        font-size: 32px !important;
        font-weight: 400;
        line-height: 36px !important;
        font-family: 'Roboto';
      }
    }
  }
  // Form modes
  .login-signin,
  .login-signup,
  .login-forgot {
    display: none;
  }

  &.login-signin-on {
    .login-signup {
      display: none;
    }

    .login-signin {
      display: block;
    }

    .login-forgot {
      display: none;
    }
  }

  &.login-signup-on {
    .login-signup {
      display: block;
    }

    .login-signin {
      display: none;
    }

    .login-forgot {
      display: none;
    }
  }

  &.login-forgot-on {
    .login-signup {
      display: none;
    }

    .login-signin {
      display: none;
    }

    .login-forgot {
      display: block;
    }
  }
}

// Desktop view
@include media-breakpoint-up(lg) {
  .login.login-1 {
    .login-aside {
      width: 50%;
      // max-width: 600px;
    }

    .login-form {
      width: 100%;
      max-width: 400px;
    }
  }
}

// Tablet and Mobile mode
@include media-breakpoint-between(lg, xl) {
  .login.login-1 {
    .login-aside {
      width: 50%;
      // max-width: 400px;
    }
  }
}

// Tablet mode
@include media-breakpoint-down(md) {
  .login.login-1 {
    .login-form {
      width: 100%;
      max-width: 350px;
    }
  }
}

// Mobile mode
@include media-breakpoint-down(xs) {
  .login.login-1 {
    .login-form {
      width: 100%;
      max-width: 100%;
    }
  }
}

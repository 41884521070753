.order-marker {
  .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    background-color: #fff;
    border: 8px solid #111;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -75px);
    cursor: pointer;
    z-index: 20;
    justify-content: center;
    align-items: center;
    display: flex;
    transition: all ease 0.5s;

    &:hover {
      z-index: 21;
    }

    &::before {
      width: 0;
      height: 0;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-top: 23px solid #111;
      position: absolute;
      content: '';
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      transition: all ease 0.5s;
    }

    .order-icon {
      font-size: 18px;
      color: #111;
    }
  }

  .order-details-wrapper {
    background-color: #111;
    color: #fff;
  }

  &.order-finished {
    .circle {
      border: 8px solid #448e73;

      &::before {
        border-top: 23px solid #448e73;
      }
    }

    .order-details-wrapper {
      background-color: #448e73;
    }
  }

  &.order-in-progress {
    .circle {
      border: 8px solid #f9b232;

      &::before {
        border-top: 23px solid #f9b232;
      }
    }

    .order-details-wrapper {
      background-color: #f9b232;
      // color: #111;
    }
  }

  &.order-in-progress {
    .circle {
      border: 8px solid #f9b232;

      &::before {
        border-top: 23px solid #f9b232;
      }
    }

    .order-details-wrapper {
      background-color: #f9b232;
      // color: #111;
    }
  }

  .order-details-wrapper {
    position: absolute;
    transform: translate(-50%, 15px);
    width: 250px;
    padding: 15px;
    opacity: 0;
    transition: ease-in-out 0.25s all;
    top: 10px;
    border-radius: 5px;
    font-family: Poppins, Helvetica, sans-serif;
    z-index: 25;

    &.show {
      opacity: 1;
      transform: translate(-50%, 0px);
    }

    .order-details {
      padding-bottom: 10px;

      .time {
        position: absolute;
        right: 10px;
        top: 10px;
      }

      .order-schedule {
        font-size: 14px;
        margin-bottom: 10px;
      }

      .order-details-text {
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .order-vendor {
      border-top: 1px solid #ebedf3;
      padding-top: 10px;
    }

    .no-vendor {
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      padding: 15px 0 10px;
      cursor: pointer;
    }
  }
}

.vendors-side {
  height: 100%;
  top: 0;
  right: 0;
  width: 300px;
  background-color: #fff;

  .vendors-list {
    display: flex;
    flex-direction: column;
  }
}

.side-vendor {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ebedf3;

  .vendor-data {
    margin-left: 20px;
  }

  .vendor-profile-picture {
    width: 40px;
    background-color: #1e1e2d;
    border-radius: 50px;
    padding: 5px;
    height: 40px;
  }

  .vendor-status {
    margin-left: auto;

    i {
      font-size: 25px;
    }

    .vendor-busy {
      color: red;
    }
  }

  &.on-map {
    border-bottom: none;
    padding: 0px;
  }

  .vendor-unread-messages {
    color: white;
    background-color: red;
    border-radius: 50px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.chat-container {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    overflow: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: solid 1px gray;
    border-radius: 10px;
    .chat-list {
        min-width: 240px;
        max-width: 380px;
        background-color: white;
        overflow: auto;
    }
    .right-panel {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 20px;
    }

    .message-list {
        flex: 1;
        min-width: 140px;
        overflow: auto;
    }

    .input-area {
        height: 50px;
        background: #518bb1;
    }

    .rce-mbox {
        max-width: 500px;
        &.rce-mbox-right{
            background-color: #518bb1;
            .rce-mbox-body{
                color:white;
            }
            .rce-mbox-time{
                color:white;
            }
            .rce-mbox-right-notch{
                fill: #518bb1;
            }
        }
    }
    .rce-mbox-photo--img img {
        height: auto;
        width: 100%;
        object-fit:contain;
        background-color: white;
    }

    .rce-container-input {
        background: none;
        .rce-input {
            border-radius: 30px;
            height:45px;
            padding-left: 15px;
        }
    }
    .send-image{
        width:35px;
        height:35px;
        cursor: pointer;
        path{
            fill:#518bb1;
        }
    }
    .voice-note{
        display: flex;
        justify-content: center;
        align-items: center;
        .fa-microphone{
            color:#518bb1;
            font-size:23px;
            cursor:pointer;
            margin:0 10px;
        }
        .fa-stop-circle{
            color:red;
            font-size:23px;
            cursor:pointer;
            margin:0 10px;
        }

    }
}

@import './base';
@import './aside';
.ui.secondary.vertical.menu > .item {
  font-family: "Noto Kufi Arabic", sans-serif !important;
}
.ui.tabular.menu .item {
  font-family: "Noto Kufi Arabic", sans-serif !important;
  
}

.ui.input > input {
  font-family: "Noto Kufi Arabic", Lato, 'Helvetica Neue', Arial, Helvetica,
    sans-serif !important;

    ::placeholder{
      font-family: "Noto Kufi Arabic", Lato, 'Helvetica Neue', Arial, Helvetica,
      sans-serif !important;
    }
}
textarea {
  font-family: "Noto Kufi Arabic", Lato, 'Helvetica Neue', Arial, Helvetica,
    sans-serif !important;
}
.date-input-picker {
  font-family: "Noto Kufi Arabic", Lato, 'Helvetica Neue', Arial, Helvetica,
    sans-serif !important;
}
.react-datepicker {
  font-family: "Noto Kufi Arabic", Lato, 'Helvetica Neue', Arial, Helvetica,
    sans-serif !important;
}

.PhoneInput {
  input {
    font-family: "Noto Kufi Arabic", Lato, 'Helvetica Neue', Arial, Helvetica,
      sans-serif !important;
  }
}
.order-details-wrapper {
  font-family: "Noto Kufi Arabic", Poppins, Helvetica, sans-serif !important;
}

.configuration-font {
  font-family: "Noto Kufi Arabic", Poppins, Helvetica, sans-serif !important;
}
.buttun-type-link {
  margin-right: 4px !important;
}
.buttun-type-link {
  .angle.right.icon {
    transform: rotate(180deg) !important;
  }
}

.black.image.large.icon.middle.aligned {
  padding-left: 4px !important;
}

.ui.dropdown .menu > .header {
  text-align: right !important;
}
.react-international-phone-input-container {
  direction: ltr !important;
}

.fc-toolbar-title {
  font-family: "Noto Kufi Arabic", Poppins, Helvetica, sans-serif !important;
}
.email-password-ltr {
  direction: ltr !important;
  text-align: end !important;
  font-family: "Noto Kufi Arabic", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Noto Kufi Arabic", sans-serif !important;
}

button {
  font-family: "Noto Kufi Arabic", sans-serif !important;
}

.no-data-container .rpt-container  .rpt-arrow {
  position: absolute;
  left: 0;
  z-index: -8;
  top: -1px;
}

.date-input-picker {
  font-family: "Noto Kufi Arabic"!important;
}

.PhoneInput {
  input {
    font-family: "Noto Kufi Arabic", sans-serif !important;
  }}


.aside-modal {
  box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 8%);
  background-color: #ffffff;
  border-top: 1px solid #ebedf3;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 100%;
  width: 100%;
  max-width: 50vw;
  height: 100%;
  transition: transform 0.2s ease-in;
  z-index: 100;

  .alert {
    margin-inline: 18px;
    background-color: #ff3835;
    text-align: center;
    color: #d14040;
    // font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    margin-top: 32px;
    margin-bottom: 0;
    border-radius: 4px;
    border: 1px solid #d14040;
    background: rgba(209, 64, 64, 0.1);
  }

  .aside-modal .body .buttons.basic .button.active {
    background: #f5f6f8 !important;
    border: none !important;
  }

  .body {
    .barcode-btns {
      gap: 15px;
      button {
        border-radius: 8px;
        border: 2px solid #e4e6ea;
        font-size: 14px;
        line-height: 20px;
        color: #44546f;
        padding: 6px 4px 6px 5px !important;

        &.active {
          border: 2px solid #0c66e4;
          background-color: #e9f2ff;
          color: #0c66e4;
        }
      }
    }
    .buttons {
      &.basic {
        .button {
          &.active {
            background: #e9f2ff !important;
            color: #0c66e4;
          }
          &.negative {
            background: #fff6f6 !important;
            color: #9f3a38 !important;
          }
        }
      }
    }
    .store-details {
      .ui.segment {
        background-color: rgba(233, 242, 255, 0.3);
        border-radius: 8px;
        border: 2px solid #0c66e4;
      }
    }
  }

  .body {
    .buttons {
      &.basic {
        &.add-people-btn {
          .button {
            border: none !important;
            border-radius: 0px !important;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.15px;
            font-family: "Roboto";
            font-weight: 500;
            color: #788ca5;
            background-color: transparent !important;
            padding: 0;

            &.active {
              color: #172b4d !important;
              border-bottom: 1px solid black !important;
            }
            &.negative {
              // background: #fff6f6 !important;
              color: #9f3a38 !important;
            }
          }
        }
      }
    }
  }

  &.active {
    transform: translateX(-100%);
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  }

  &.preview {
    .body {
      padding: 30px 0;
      overflow-x: hidden;

      .pane-body {
        padding: 0 25px;
      }
    }
  }

  .details-wrapper {

    .icon-container{
      gap: 5px;
    }
    &.work-request {
      margin-top: -20px;
    }

    .notes-container {
      border: 1px solid #e4e6ea;
      border-radius: 6px;
      padding: 8px;
    }
    .status-dropdown {
      width: 200px;
      border-radius: 3px;
      border: 2px solid rgba(9, 30, 66, 0.14);
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        margin-bottom: 30px;

        .label:not(.circular) {
          font-size: 13px;
          color: #707070;
          display: block;
          background: transparent;
          border-radius: 0;
          height: auto;
          width: auto;
          margin-bottom: 5px;
        }

        .big-label {
          font-size: 15px;
          margin-bottom: 5px;
        }

        .value {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }

  .modal-header {
    border-bottom: 1px solid #ebedf3;
    padding: 30px 25px 25px;
    background-color: rgba(233, 242, 255, 0.5) !important;

    .body {
      &.barcode-btns {
        button {
          border-radius: 8px;
          border: 2px solid #e4e6ea;
          font-size: 14px;
          line-height: 20px;
          color: #44546f;
        }
      }
    }

    > .flexbox {
      width: 100%;
    }

    div {
      i {
        cursor: pointer;
        color: #878c90;
        margin: 0 5px;
        // height: 0;
        padding: 0;
        &:last-child {
          margin-right: 0;
        }
      }
      span {
        font-size: 20px;
        font-weight: 700;
      }
      .work-order-preview-tooltip {
        font-size: 9px !important;
        font-weight: 400;
        font-family: "Roboto";

        &.parts {
          line-height: 18px !important;
        }
      }
    }

    .name {
      // font-size: 22px;
      color: #172b4d;
      /* Title Large 22\28 */
      // font-family: 'Roboto';
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 127.273% */
    }
  }

  .ui {
    &.card {
      &.card-selectable {
        .header {
          height: auto;
          border-bottom: 0;
          padding: 0;
        }
      }
    }
  }

  .body {
    padding: 30px 25px;
    overflow: auto;

    .field {
      .ui {
        &.dropdown {
          &.selection {
            .label {
              height: auto;
            }
          }
        }
      }
    }
  }
}

.aside-modal-background {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 99;
  visibility: hidden;
  transition: background-color 0.2s ease-in;

  &.active {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.ui {
  &.header,
  .header {
    display: block;
    height: auto;
  }
  &.modal {
    top: auto;
    left: auto;
    height: auto;
  }

  &.button {
    &.basic {
      &.label {
        height: 100%;
      }
    }
  }

  &.card {
    &.card-selectable {
      &:hover {
        .content {
          background: rgba(0, 0, 0, 0.05);
          .header {
            background: transparent;
          }
        }
      }

      &.selected {
        .content {
          background: #21ba45;
          color: #fff;
          .header {
            background: transparent;
            color: #fff;

            i {
              color: #fff;
            }
          }
          .description {
            color: #fff;
          }
        }
      }
    }
  }

  &.tabular {
    &.menu {
      margin-bottom: 30px !important;
      padding: 0 25px;

      &::after {
        display: none;
      }

      .item {
        border: 0;
        padding: 0 !important;
        margin-right: 20px;
        &:last-child {
          // margin-right: 0;
        }
        &.active {
          border: 0;
          border-bottom: 2px solid;
          border-color: #2185d0 !important;
        }
      }
    }
  }
  &.attached.tab.bottom {
    border: 0;
    padding: 0;
  }
}

.accordion-row {
  cursor: pointer;

  .aside-counter {
    border-radius: 2px !important;
    background: var(--blue, #0c66e4);
    padding: 0px 10px;
    color: #fff;
    span {
      line-height: 25px;
      font-size: 14px;
      text-align: center;
      margin: auto;
      border: 1px solid;
      height: 25px;
      width: 25px;
      border-radius: 3.7px;
      background-color: #2185d0;
      color: #fff;
    }

    i {
      font-size: 18px;
      color: #000;
    }
  }
}

.sub-header-count {
  // border-radius: 2px !important;
  // background: rgba(233, 242, 255, 1);
  // padding: 3px 9px 1px 6px;
  // color: #0c66e4;

  // .link-text {
  //   &.location {
  //     span {
  //       :hover {
  //         background: #0c66e4;
  //         color: white;
  //       }
  //     }
  //   }
  //   :hover {
  //     background: #0c66e4;
  //     color: white;
  //   }
  // }

  .sub-location-tooltip {
    padding: 10px;
    background-color: #788ca5;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    line-height: 11px;
    border-radius: 5px;
  }
}

.location-length {
}

.custom-container {
  border-radius: 4px;
  background: #f5f6f8;
  padding: 7px 0px 0px 11px;

  .custom-label {
    color: var(--main-text, #172b4d);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
  }
}

.sub-location {
  cursor: pointer;
  background-color: rgba(13, 13, 14, 0.03);
}

.buttun-type-link {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0;
  border: 0;
  box-shadow: none;
  color: #2185d0;
  line-height: 1;
  i {
    color: #2185d0;
  }
  p {
    color: #0c66e4;
    /* Body Large 16\24 */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
  }

  .customer-add-btn {
    background: #e9f2ff;
    padding: 4px;
    border-radius: 24px;
    
  }

  &.remove {
    color: #f11818;
    i {
      color: #f11818;
    }
  }
}

.configure-icon {
  svg {
    height: 23px;
    width: 23px;
    margin-left: -2px;
  }
}

.configure-text {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, Helvetica Neue,
    Segoe UI, Helvetica, Arial, sans-serif !important;

  &.arabic-text {
    font-family: "Noto Kufi Arabic" !important;
  }
}

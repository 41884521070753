.ui {
  &.table {
    text-align: right;
  }

  &.header {
    font-family: 'Noto Kufi Arabic', sans-serif;
  }

  &.button {
    font-family: 'Noto Kufi Arabic', sans-serif;
    i {
      margin: 0 -0.21428571em 0 0.42857143em !important;
    }
  }

  &.input {
    > input {
      text-align: right;
    }
  }

  &.dropdown {
    text-align: right;
    &.selection {
      > .dropdown {
        &.icon {
          right: auto;
          left: 1em;
        }
      }
    }

    &.search {
      > .text {
        left: auto;
        right: 0;
      }
    }

    .menu {
      > .item {
        text-align: right;
        font-family: 'Noto Kufi Arabic', sans-serif !important;
      }
    }
  }

  &.pagination {
    &.menu {
      > .item {
        &:first-child,
        &:last-child,
        &[type='prevItem'],
        &[type='nextItem'] {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.aside-modal {
  right: 100%;
  left: auto;

  &.active {
    transform: translateX(100%);
  }
}

.react-international-phone-country-selector-dropdown {
  right: var(--react-international-phone-dropdown-left, 0) !important;
}

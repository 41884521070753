//
// Avatar
//

// Base
.image-input {
  position: relative;
  display: inline-block;
  @include border-radius($border-radius);
  background-repeat: no-repeat;
  background-size: cover;

  // Wrapper
  .image-input-wrapper {
    width: 120px;
    height: 120px;
    @include border-radius($border-radius);
    background-repeat: no-repeat;
    background-size: cover;
  }

  // Change Button
  [data-action="change"] {
    cursor: pointer;
    position: absolute;
    right: -10px;
    top: -10px;

    input {
      width: 0 !important;
      height: 0 !important;
      overflow: hidden;
      opacity: 0;
    }
  }

  // Cancel & Remove Buttons
  [data-action="cancel"],
  [data-action="remove"] {
    position: absolute;
    right: -10px;
    bottom: -5px;
  }

  [data-action="cancel"] {
    display: none;
  }

  // Input Changed State
  &.image-input-changed {
    [data-action="cancel"] {
      display: flex;
    }

    [data-action="remove"] {
      display: none;
    }
  }

  // Input Empty State
  &.image-input-empty {
    [data-action="remove"],
    [data-action="cancel"] {
      display: none;
    }
  }

  // Circle style
  &.image-input-circle {
    border-radius: 50%;

    // Wrapper
    .image-input-wrapper {
      border-radius: 50%;
    }

    // Change Control
    [data-action="change"] {
      right: 5px;
      top: 5px;
    }

    // Cancel & Remove Buttons
    [data-action="cancel"],
    [data-action="remove"] {
      right: 5px;
      bottom: 5px;
    }
  }

  // Bordered style
  &.image-input-outline {
    .image-input-wrapper {
      border: 3px solid $white;
      box-shadow: $box-shadow;
    }
  }
}

.upload-box {
  border-width: thick;
  padding: 20px;
  border: 2px solid rgb(199, 199, 199);
  border-style: dashed;
  min-height: 200px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    padding: 1rem 0rem;
    // display: flex;
    // flex-direction: column;
    text-align: center;
    .upload-icon {
      img {
        width: 10%;
        //   height: 8%;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
      padding-bottom: 1rem;
    }
    .text {
      font-weight: 400;
      display: inline;
      color: smokewhite;
    }
    .upload-btn-wrapper {
      padding-top: 1rem;
      position: relative;
      overflow: hidden;
      display: inline-block;
    }

    .btn {
      // border: 2px solid gray;
      border: none;
      color: white;
      background-image: linear-gradient(
        to right top,
        #3a5bfe,
        #2c4dff,
        #1f3dff,
        #1428fe,
        #0f00fc
      );
      padding: 10px 40px;
      border-radius: 3px;

      // border-radius: 20px;
      font-size: 1.1rem;
      font-weight: 500;
    }

    .upload-btn-wrapper input[type="file"] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }
}

.order-list-wrapper {
  display: flex;
}
.orders-list {
  width: 100%;
  .order-item {
    cursor: pointer;

    &:hover,
    &.active {
      background-color: #fbfbfb !important;
    }
  }
  .order-vendor {
    border-top: 1px solid #ebedf3;
    padding: 15px;
    min-height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .order-header {
    font-size: 15px;
    margin-bottom: 10px;
    color: #000 !important;
    font-weight: bold;
  }
  .order-details {
    font-size: 14px;
    color: #000 !important;
    margin-right: 20px;
  }
  .no-vendor {
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    // padding: 0 0;
    cursor: pointer;
  }
  .order-card {
    background-color: white;
  }
}

.order-status {
  text-align: center;
  div {
    font-size: 12px;
  }
}

.evidences-wrapper {
  margin-top: 20px;
  .evidence-item {
    display: block;
    width: 25%;
    height: 130px;
    margin-bottom: 5px;
    margin-right: 5px;
    overflow: hidden;
    border-radius: 5px;

    .evidence-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@import 'aside_modal';

.field {
  // margin-bottom: 20px;
  min-height: 85px;

  &:last-child {
    margin-bottom: 0;
  }

  .ui {
    &.buttons {
      &.basic {
        .button {
          &.active {
            background: #e9f2ff !important;
            color: #0C66E4 !important;
          }
        }
      }
    }
  }
}

.filters-wrapper {
  .buttons {
    &.ui {
      &.basic {
        .active-button {
          background: #e2f5fc !important;
          &:focus {
            background: #e2f5fc !important;
          }
        }
      }
    }
  }

  @media (max-width: 1450px) {
    flex-wrap: wrap;
    gap: 15px;
  }
}

.label-item {
  display: block;
  margin: 0 0 0.28571429rem 0;
  // color: rgba(0, 0, 0, 0.87);
  // font-size: 14px !important;
  // font-weight: 700;
  text-transform: none;
  color: #172b4d;
  /* Body Large 16\24 */
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;

  &.parts-minimum-qty{
    font-size: 15.5px !important;
  }
}

.estimation-time-div {
  position: relative;

  .year-text {
    color: #788ca5;
    position: absolute;
    right: 0;
    font-size: 16px;
    font-style: normal;
    margin: 8px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
  }
  span {
    /* margin-right: 123px; */
    position: absolute;
    z-index: 1;
    margin: 5px;
    overflow: visible !important;
  }
}

.qr-code-container {
  // text-align: center;
  /* Title Medium 16\24 */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;
}

.label-error {
  margin: 0.28571429rem 0;
  color: #cd2929;
  font-size: 12px;
  font-weight: 700;
}

.accordion {
  margin-bottom: 10px;
  .title {
    font-size: 1.2em;
  }
}

.upload-field {
  img {
    cursor: pointer;
  }

  .upload-button {
    position: relative;
    display: inline-block;

    i {
      position: absolute;
      cursor: pointer;
      font-size: 3em;
      color: #fff;
      width: 40px;
      height: 40px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      opacity: 0;
    }

    &:hover {
      i {
        opacity: 1;
      }
    }
  }
}

.option-group {
  margin: 10px;
  border-top: 1px solid #e1e1e1;
  padding-top: 10px;
}

.ui {
  &.dropdown {
    &.button {
      &.icon {
        &.blue {
          i {
            &.dropdown {
              &.icon {
                color: #2185d0 !important;
              }
            }
          }
        }
      }
    }
  }
}



.ui.form .field > label {
  //
  color: #172b4d !important;
  /* Body Large 16\24 */
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.ui.input.location-address input::placeholder {
  /* Add additional styles as needed */

  padding-left: 20px !important; /* Placeholder text color */
  /* Add additional styles as needed */
}
.ui.input.location-address-rtl input::placeholder {
  /* Add additional styles as needed */

  padding-right: 20px !important; /* Placeholder text color */
  /* Add additional styles as needed */
}

.filters-wrapper {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    > .ui {
      &.labeled {
        &.icon {
          &.button {
            padding-left: 36px !important;

            > .icon {
              max-width: 34px;
              background-color: transparent;
              color: #878c90;
            }

            .menu {
              > .item {
                &.selected {
                  background-color: #e2f5fc !important;
                }
                > .icon {
                  color: #878c90;
                }
              }
              > .input {
                .icon {
                  color: #878c90;
                }
              }

              .tabs {
                .item {
                  color: rgba(0, 0, 0, 0.85);
                  font-size: 0.78571429em;
                  font-weight: 700;
                  text-transform: uppercase;
                  i {
                    font-size: 1rem;
                  }
                  &.active {
                    color: #2185d0;
                  }
                }
              }
            }
          }
        }
      }

      &.input {
        &.icon {
          width: 330px;
          input::placeholder {
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }
}

.checkmark-wrapper {
  background-color: #6cd3bb;
  width: 120px;
  height: 120px;
  max-height: 120px;
  border-radius: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  i {
    color: #fff;
  }

  &.red {
    background-color: #c03939;
  }
}

.password-container {
  position: relative;
}

.password-toggle {
  position: absolute;
  right: 34px;
  top: 69%;
  transform: translateY(-50%);
  cursor: pointer;
}
.password-toggle-rtl {
  position: absolute;
  left: 35px;
  top: 69%;
  transform: translateY(-50%);
  cursor: pointer;
}

.aside-fixed {
  &.aside {
    left: auto;
    right: 0;
  }

  + .wrapper {
    padding-right: 300px;
    padding-left: 0;
  }
}

.header#kt_header {
  &.header-fixed {
    right: 300px;
    left: 0;
  }
}

.subheader#kt_subheader {
  right: 300px;
  left: 0;
}
.custom-select-filter {
  .visible.menu.transition {
    left: 50px !important;
  }
}

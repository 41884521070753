@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.cdnfonts.com/css/sf-pro-display");

@font-face {
  font-family: "TheSans-Plain";
  src: url("https://db.onlinewebfonts.com/t/5c152170e85f42361141a732c78f11ad.eot");
  src: url("https://db.onlinewebfonts.com/t/5c152170e85f42361141a732c78f11ad.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/5c152170e85f42361141a732c78f11ad.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/5c152170e85f42361141a732c78f11ad.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/5c152170e85f42361141a732c78f11ad.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/5c152170e85f42361141a732c78f11ad.svg#TheSans-Plain")
      format("svg");
}

@font-face {
  font-family: "Noto Kufi Arabic";
  src: url("https://db.onlinewebfonts.com/t/3f157c3bb9fe4d694384fc9b1c1ea9b2.eot");
  src: url("https://db.onlinewebfonts.com/t/3f157c3bb9fe4d694384fc9b1c1ea9b2.eot?#iefix")
      format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/3f157c3bb9fe4d694384fc9b1c1ea9b2.woff2")
      format("woff2"),
    url("https://db.onlinewebfonts.com/t/3f157c3bb9fe4d694384fc9b1c1ea9b2.woff")
      format("woff"),
    url("https://db.onlinewebfonts.com/t/3f157c3bb9fe4d694384fc9b1c1ea9b2.ttf")
      format("truetype"),
    url("https://db.onlinewebfonts.com/t/3f157c3bb9fe4d694384fc9b1c1ea9b2.svg#Noto Kufi Arabic")
      format("svg");
}

/** 
Procedure 
*/
.procedure-container {
  font-family: "Roboto" !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  background-color: #f9fcff;
  padding: 0px 17px 50px 16px;
  .ui.selection.dropdown {
    height: 34px !important;
    padding: 7px 12px !important;
  }

  .ui.selection.dropdown {
    padding: 7px 12px;
    // height: 34px !important;
    // padding: 0.58571429em 2.1em 0.58571429em 1em !important;
    // margin: auto 0 !important;
    // display: flex;
    // align-items: center;
    // .divider.text {
    //   top: 3px;
    // }
    &.multiple {
      height: fit-content !important;
      .dropdown.icon {
        top: 1em !important;
      }
    }
  }

  .multi-profile-container {
    display: flex;
    img {
      margin: 0px;
      margin-left: -15px !important;
    }
    .img-first {
      margin-left: 0px !important;
    }
    img:nth-child(2) {
      // right: 20px;
      margin-left: -20px;
    }
    img:nth-child(3) {
      margin-left: -40px;
    }

    img:hover {
      z-index: 1;
    }
  }

  .procedure-title {
    background: none;
    font-family: "Roboto", sans-serif !important;
  }
  .procedure-intro-value {
    color: #788ca5;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
  }

  .pill {
    border-radius: 10px;
    border: 1px solid #e9f2ff;
    background: #e9f2ff;
    color: #0c66e4;
    text-align: start;
    // min-width: 67px;
    // font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    padding: 3px 5px;
    min-width: 87px;
  }

  .ui.tabular.menu .active.item {
    color: #172b4d;
    text-align: center;
    /* Title Medium 16\24 */

    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
  }

  .detail-title {
    word-wrap: break-word;
    width: 472px;
    color: #172b4d;
    /* Headline Medium 28\36 */
    //font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 128.571% */
    margin-bottom: 0px;
  }

  .detail-intro-value {
    margin-left: 16px;
    margin-right: 16px;
    color: #172b4d;

    /* Body Large 16\24 */
    //font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.5px;
    .detail-intro-link {
      color: #0c66e4;
      text-align: center;
      word-break: break-all;
      //font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: 0.5px;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  .detail-updated-title {
    color: #788ca5;
    /* Body Small 12\16 */
    //font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.4px;
  }

  .ui.tabular.menu .item.active {
    border-bottom: 1px solid #172b4d !important;
  }
  .ui.tabular.menu .item {
    color: #788ca5;
    margin: 0px;
    padding-left: 32px !important;
    padding-right: 32px !important;
    color: #788ca5;
    text-align: center;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
  }

  .table-header {
    margin-right: 23px;

    .header-date {
      p {
        text-align: right;
        margin-bottom: 15px;
        color: #44546f;
        // font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: 0.25px;
      }
    }
  }

  .header-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    .add-item-btn:hover {
      background-color: #0a55bd !important;
    }
  }

  h2.ui.header {
    //font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: 400;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
    color: #172b4d;
    font-style: normal;
    line-height: 32px;
    /* 133.333% */
  }

  .ui.primary.button {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    background-color: #0c66e4;
    border-radius: 3px;
    width: auto;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    margin-right: 0;
  }

  .no-procedure-text {
    color: #788ca5;
    /* Title Large 22\28 */
    //font-family: Roboto;
    font-size: 22px;
    font-style: normal;
    font-weight: 330px;
    line-height: 28px;
    /* 127.273% */
    margin-top: 8px;
  }

  .no-procedure-container {
    text-align: center;
    height: 675px;
    display: flex;
    align-items: center;
    justify-content: center;
    .no-work-orders {
      .no-data-text {
      }

      .no-data-text {
        color: #44546f;
        /* Title Large 22\28 */
        //font-family: Roboto;
        font-size: 22px;
        font-style: normal;
        font-weight: 330px;
        line-height: 28px;
        /* 127.273% */
        margin-top: 8px;

        .link-text {
          color: #0c66e4;
        }

        .work-order-tooltip {
          color: white !important;
          margin: -2px 0px -px 0px;
        }
      }
      p {
        margin-top: 23px;
        margin-bottom: 32px;
        font-size: 28px !important;
        font-weight: 400 !important;
      }
      .work-order-button-transparent {
        border-radius: 3px;
        border: 1px solid #0c66e4;
        /* .Internal/Button/Button Label */
        background-color: #e9f2ff;
        padding: 6px 24px;

        color: #0c66e4;
        text-align: center;
        /* Body Large 16\24 */
        font-family: "Roboto";
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: 0.5px;
        &:hover {
          background-color: #0c66e4 !important;
          color: #e9f2ff !important;
        }
      }
    }
  }

  .procedure-filter {
    border-radius: 10px;
    border: 1px solid rgba(14, 40, 87, 0.03);
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(14, 40, 87, 0.02);
    background-color: #fff;
    padding: 23px 24px 30px 31px;

    .procedure-table {
      border-radius: 0px;
    }

    .filters-wrapper {
      margin-bottom: 0px;
    }

    .filters-wrapper > div > .ui.input.icon {
      width: 240px;
    }

    .datatable-div {
      border-radius: 0px;
    }

    .ui.input > input {
      border: 2px solid rgba(9, 30, 66, 0.14);
      border-radius: 5px !important;
      height: 34px;
      font-size: 14px;
      font-weight: 400;
      color: #172b4d;
      padding-left: 35px !important;

      &::placeholder {
        color: #44546f !important;
        font-size: 14px;
      }
      &:focus {
        border: 2px solid blue !important ;
      }
    }

    .ui.icon.input > i.icon {
      display: none;
    }

    .table-searchbar {
      position: relative;

      .search-svg {
        position: absolute;
        z-index: 1;
        margin: 8px;
        overflow: visible !important;
      }

      > svg {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 10px;
        z-index: 1;
      }
    }

    .table-right-dropdown {
      // height: 34px;
      flex-wrap: wrap;
      gap: 16px;

      .created-calendar {
        position: relative;

        .ui.basic.button {
          height: 100%;
          border: 2px solid rgba(9, 30, 66, 0.14);
          border-radius: 5px;
          font-family: "Roboto", sans-serif;
          font-size: 14px;
          font-weight: 400;
          color: #172b4d !important;
          width: 125px;
          padding: 8px 30px 8px 8px !important;
          box-shadow: none;
          margin-right: o;
        }

        .ui.basic.active.button {
          background: none !important;
        }

        .ui.labeled.icon.button > .icon {
          display: none;
        }

        > svg {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 10px;
        }
      }
    }

    .filters-wrapper > div > .ui.labeled.icon.button > .icon {
      display: none;
    }
  }
  .type-parent,
  .procedure-item-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  .procedure-item-parent {
    border-radius: 8px 8px 0 0;
    background-color: rgba(245, 246, 248, 1);
    border: 1px solid rgba(228, 230, 234, 1);
    padding: 16px;
    /* color: #0c66e4; */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;

    &.header-div {
      // border: 1px solid #0c66e4;
      background-color: #e9f2ff;
      border-color: #0c66e4;
      border-radius: 8px;
      border: none !important;
      border-bottom: 1px solid #0c66e4 !important;
      align-items: center;

      &.show-header {
        border: 1px solid #0c66e4 !important;
      }
      .head-title {
        font-size: 22px;
        color: #44546f;
      }
    }
    // border-radius: 8px 8px 0 0;
    // background-color: #e9f2ff;
    // border: 1px solid #0c66e4;
    // padding: 16px;
    // color: #0c66e4;
    // /* Title Medium 16\24 */
    // //font-family: Roboto;
    // font-size: 16px;
    // font-style: normal;
    // font-weight: 500;
    // line-height: 24px; /* 150% */
    // letter-spacing: 0.15px;
    .type-parent {
      flex-shrink: 0;
      gap: 8px;
      margin-left: -8px;
      color: #44546f;
    }
  }

  .green {
    color: #4fab68;
    background: rgba(79, 171, 104, 0.1);
    border: 1px solid #4fab68;
  }
  .procedure-sub-item-parent {
    border: 1px solid #e4e6ea;
    margin-bottom: 24px;
    padding: 16px;

    .item-value {
      .ui.secondary.segment {
        padding: 8px 16px 8px 16px;
        border-radius: 8px;
      }
    }
  }
  .procedure-item-option {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    input {
      margin-top: 14px !important;
    }

    .procedure-item-action {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: 0.15px;
      margin-bottom: 20px;
    }
    p {
      padding: 8px;
      margin: 0px;
      color: #44546f;
      /* Body Medium 14\20 */
      //font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.25px;
    }
    input[type="checkbox"]:checked {
      color: #0c66e4; /* Change text color to blue when checked */
    }
    .procedure-item-option-radio {
      // clip-path: circle(50% at 50% 50%);
      width: 13px;
      height: 13px;
      background-color: white;
      border-radius: 50%;
      vertical-align: middle;
      border: 1px solid #ddd;
      appearance: none;
      -webkit-appearance: none;
      outline: none;
      cursor: pointer;
    }

    .procedure-item-option-radio:checked {
      appearance: auto;
      -webkit-appearance: auto;
      background-color: #0c66e4;
      clip-path: circle(50% at 50% 50%);
    }
  }
  .procedure-item-header {
    color: #172b4d;
    /* Title Medium 16\24 */
    //font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    margin-bottom: 20px;
  }
  .procedure-intro {
    .procedure-intro-label {
      margin-bottom: 0px;
      color: #788ca5;

      /* Body Medium 14\20 */
      //font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.25px;
      &.procedure-label {
        min-width: 79px !important;
      }
    }
    .procedure-intro-value {
      margin-left: 16px;
      margin-right: 16px;
      color: #172b4d;

      /* Body Large 16\24 */
      //font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: 0.5px;
      .procedure-intro-link {
        color: #0c66e4;
        text-align: center;
        //font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: 0.5px;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }
  .procedure-data-header {
    .header-pill-container {
      display: flex;
      align-items: center;
      gap: 0px;
      .procedure-title {
        // width: 250px;
        color: #172b4d;
        /* Headline Medium 28\36 */
        //font-family: Roboto;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; /* 128.571% */
        margin-bottom: 0px;
      }
    }
    .header-title {
      .header-id {
        label {
          color: var(--middle_grey, #788ca5);
          text-align: center;
          /* Body Large 16\24 */
          // font-family: 'Roboto';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          letter-spacing: 0.5px;
        }
        span {
          margin-left: 4px;
          color: #172b4d;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.5px;
        }
      }
    }
    .procedure-updated-title {
      color: #788ca5;
      /* Body Small 12\16 */
      //font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.4px;
    }
  }
  .procedure-detail-header {
    .name {
      color: #172b4d;
      /* Title Large 22\28 */
      //font-family: Roboto;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 127.273% */
    }
    .procedure-detail-header-buttons {
      .procedure-header-button {
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }

  /*
        Procedure Add & Edit form common designs
  */
  .item-button-group,
  .procedure-item-add-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .procedure-item-add-button-container {
    // position: absolute;
    // top: 888px;
    // left: 24px;
    justify-content: space-around;
    align-items: flex-start;
    gap: 14px;
    color: #0c66e4;
  }
  .item-button-group {
    flex-shrink: 0;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  .procedure-form-item-header {
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #0c66e4;
    background: #e9f2ff;
    p {
      /* Title Medium 16\24 */
      color: #0c66e4;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: 0.15px;
    }
  }

  .main-div {
    padding: 10px;
    border: 1px solid rgba(228, 230, 234, 1);
    border-radius: 3px;

    &.header {
      border: 0px solid !important;
      margin-bottom: -12px !important;
    }
  }
  .procedure-form-item-container {
    margin: 0px 0px;
    .item-option-container {
      display: flex;
      width: inherit;
      background-color: rgba(245, 246, 248, 1);
      border-radius: 3px;
      border: 1px solid #e4e6ea;
      padding: 2px;
      .input {
        width: inherit;
        input[type="text"] {
          border: none !important;
          background-color: rgba(245, 246, 248, 1) !important;
          color: #44546f !important;
          /* Body Medium 14\20 */
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.25px;
        }
      }
      input[type="checkbox"] {
        margin: 0 8px;
      }
    }
  }
  .warning-inspection-option-container {
    padding: 1em 0.5em !important;
    border-radius: 4px;
    border: 1px solid #788ca5;
    // background: rgba(226, 178, 3, 0.1) !important;
    .ui.dropdown {
      height: 20px;
      border-radius: 2px;
      background: #e2b203;
    }
  }
  .id-text-style {
    font-weight: 500 !important;
    letter-spacing: 0.15px !important;
  }
}

.container:has(.procedure-container) {
  max-width: 100%;
}

.rtl-body {
  .procedure-container {
    font-family: "Noto Kufi Arabic", sans-serif !important;
    .procedure-title {
      font-family: "Noto Kufi Arabic", sans-serif !important;
    }
    .ui.primary.button {
      font-family: "Noto Kufi Arabic", sans-serif !important;
      width: 154px;
    }
    .table-header .header-date p {
      text-align: left;
      font-family: "Noto Kufi Arabic" !important;
    }

    .procedure-filter {
      padding: 16px 31px 50px 24px;
      .table-right-dropdown {
        .created-calendar {
          .ui.basic.button {
            font-family: "Noto Kufi Arabic", sans-serif;
          }
        }
      }
    }

    .table-header {
      margin-right: 0;
      margin-left: 23px;
    }

    .procedure-filter .table-searchbar > svg {
      left: auto;
      right: 10px;
    }

    .no-procedure-text {
      font-family: "Noto Kufi Arabic", sans-serif !important;
    }
  }
}

.header-title-change-procedure {
  background-color: #f5f6f8;
  padding: 10px 4px;
  margin: -10px;

  &.blue {
    background-color: rgba(233, 242, 255, 1);
    border-radius: 8px;
  }
}
.change-svg-procedure {
  svg {
    width: 27px;
    height: 27px;
  }
}

.add-procedure-dropdown-n {
  .procedure-container .warning-inspection-option-container {
    padding: 10px 8px !important;
  }
  .custom-menu-dropdown .dropdown .option-toggle.inspection-option-dropdown {
    width: max-content !important;
    background: #fff !important;
    color: #0c66e4;
    padding: 2px 0px 0px 2px;
    font-size: 14px;
    border: 1px solid #0c66e4 !important;
  }
  .custom-menu-dropdown
    .dropdown
    .option-toggle.inspection-option-dropdown:hover {
    color: #0c66e4 !important;
  }
  .new-added-add-logic-txt {
    color: #0c66e4;
    border-radius: 3px;
    border: 1px solid #0c66e4;
    padding: 4px;
    margin: 5px;
    display: inline-block;
  }
  .custom-menu-dropdown .dropdown.show .menu .item {
    border-left: 1px solid transparent;
    width: -webkit-fill-available;
    &:hover {
      background-color: #f5f6f8;
      border-left: 1px solid #0c66e4;
    }
  }
}

.wrapper-new-label-inspection-check {
  .option-inspection {
    border: 2px solid #e4e6ea;
    border-radius: 8px;
    padding: 6px 8px;
    margin-right: 10px;

    &.red-box {
      background-color: #fff6f6;
      border-color: #d14040;
      color: #d14040;
    }

    &.green-box {
      background-color: #4FAB681A;
      border-color: #4FAB68;
      color: #4FAB68;
    }
    &.yellow-box {
      background-color: #E2B2031A; /* Light yellow background */
      border-color: #E2B203; /* Vibrant yellow border */
      color: #E2B203;
    }

    &.grey-box {
      background-color: #E4E6EA;
      border-color: #788CA5;     
      color: #44546F;          
    } &.blue-box {
      background-color: rgba(233, 242, 255, 0.3);
      border-color: #0C66E4;     
      color: #0C66E4;          
    }
  }
  .logic-box-for-isc {
    border: 1px solid #788ca5;
    border-radius: 4px;
    padding: 9px 15px 15px 15px;
  }
}
.title-text-isc {
  color: #172b4d;
  margin-left: -5px;
  font-weight: 500;
  font-size: 16px;
}
.detail-text-isc {
  color: #172b4d;
  font-weight: 400;
  font-size: 14px;
}

.file-name-show-procedure {
  background-color: #f5f6f8;
  border: 1px solid #e4e6ea;
  padding: 8px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  margin-top: 15px;

  &.details {
    justify-content: flex-start !important;
    color: #0c66e4;
  }
}

.procedure-item-option-radio-asset {
  // clip-path: circle(50% at 50% 50%);
  width: 13px;
  height: 13px;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.procedure-item-option-radio-asset:checked {
  appearance: auto;
  -webkit-appearance: auto;
  background-color: #0c66e4;
  clip-path: circle(50% at 50% 50%);
}
